import { FunctionComponent } from 'react';
import * as App from 'dde-app/app/components/App';
import { NotFoundPage } from 'dde-app/common/components/NotFoundPage';
import { StyleGuide } from 'dde-app/common/components/StyleGuide';
import { Env } from 'dde-app/config/utils/env';
import { loadData as loadHomePageData } from 'dde-app/home/components/HomePage/HomePage';
import { HomePage } from 'dde-app/home/components/HomePage/HomePage';
import { loadData as loadPhotoContestPageData } from 'dde-app/photos/photoContest/components/PhotoContestPage';
import { PhotoContestPage } from 'dde-app/photos/photoContest/components/PhotoContestPage';
import * as StaticPage from 'dde-app/staticPages/components/StaticPage';
import { Route, RouteConfig } from './';

const ROUTE_CONFIG: RouteConfig = [
  {
    ...App as Route,
    routes: [
      {
        component: HomePage as FunctionComponent,
        exact: true,
        loadData: loadHomePageData,
        path: '/',
      },
      {
        ...StaticPage,
        path: '/page/:key',
      },
      {
        component: PhotoContestPage as FunctionComponent,
        exact: true,
        loadData: loadPhotoContestPageData,
        path: '/photo-contest',
      },
      {
        component: process.env.NODE_ENV === Env.DEVELOPMENT
          ? StyleGuide as FunctionComponent
          : NotFoundPage as FunctionComponent,
        exact: true,
        path: '/style-guide',
      },
      {
        component: NotFoundPage as FunctionComponent,
      },
    ],
  },
];

export {
  ROUTE_CONFIG,
};
