import { withAnalytics } from 'dde-app/analytics/hoc/withAnalytics';
import { ANALYTICS_PAGE_TYPE } from 'dde-app/analytics/utils/analytics';
import { loadData } from './loadData';
import { StaticPage as StaticPageComponent } from './StaticPage';

const StaticPage = withAnalytics(
  StaticPageComponent,
  ANALYTICS_PAGE_TYPE.general,
);

export * from './types';

export {
  StaticPage as component,
  loadData,
  StaticPageComponent,
};
