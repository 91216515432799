import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { subscribe } from 'dde-app/newsletter/redux/actions';

type UseSubscribeReturn = typeof subscribe;

const useSubscribe = (): UseSubscribeReturn => {
  const dispatch = useAppDispatch();
  return bindActionCreators(subscribe, dispatch);
};

export {
  useSubscribe,
};
export type {
  UseSubscribeReturn,
};
