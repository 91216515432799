import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
// @ts-ignore
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { cancelTasks } from 'src/server/actions/actionCreators';
import { Store } from 'dde-app/app/redux/types';
import { history } from 'dde-app/common/utils/history';
import { isServer } from 'dde-app/common/utils/server';
import { Env } from 'dde-app/config/utils/env';
import { reducer } from './reducer';

const createStore = (initialState = {}): Store => {
  const sagaMiddleware = createSagaMiddleware();

  const configureStoreOptions: ConfigureStoreOptions = {
    devTools: process.env.NODE_ENV === Env.DEVELOPMENT,
    middleware: [
      sagaMiddleware,
      createReduxWaitForMiddleware(),
      routerMiddleware(history),
    ],
    reducer,
  };

  if (isServer) {
    configureStoreOptions.preloadedState = initialState;
  } else {
    configureStoreOptions.preloadedState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
  }

  const enhancedStore = configureStore(configureStoreOptions);

  const runSaga = sagaMiddleware.run;
  const close = () => enhancedStore.dispatch(cancelTasks());

  return Object.assign(
    {},
    enhancedStore,
    {
      close,
      runSaga,
    },
  );
};

export {
  createStore,
};
