import { all, fork } from 'redux-saga/effects';
import { saga as flightSaga } from 'dde-app/contest/redux/flight/saga';
import { saga as rewardSaga } from 'dde-app/contest/redux/reward/saga';
import { saga as voteSaga } from 'dde-app/contest/redux/vote/saga';
import { saga as winnersSaga } from 'dde-app/contest/redux/winners/saga';

function* saga(): Generator<any, any, any> {
  yield all([
    fork(flightSaga),
    fork(rewardSaga),
    fork(voteSaga),
    fork(winnersSaga),
  ]);
}

export {
  saga,
};
