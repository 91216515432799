import React, { useCallback } from 'react';
import classNames from 'classnames';
import { upperCaseFirst } from 'upper-case-first';
import { Component, Props } from './types';
import './Button.scss';

enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

enum Size {
  DEFAULT = 'default',
  LARGE = 'large',
  SMALL = 'small',
}

enum StyleType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'light',
}

const Button: Component = ({
  className,
  children,
  iconPosition,
  isDisabled = false,
  isFullWidth = false,
  isFormSubmitPrevented = false,
  onClick,
  size = Size.DEFAULT,
  styleType = StyleType.PRIMARY,
}: Props) => {
  const rootClassName = classNames(
    'Button',
    `Button--${upperCaseFirst(size)}`,
    `Button--${upperCaseFirst(styleType)}`,
    {
      'Button--Disabled': isDisabled,
      'Button--FullWidth': isFullWidth,
      [`Button-Icon--${upperCaseFirst(iconPosition || '')}`]: !!iconPosition,
    },
    className,
  );

  const handleClick = useCallback(
    () => !isDisabled && onClick && onClick(),
    [isDisabled, onClick],
  );

  return (
    <button
      className={rootClassName}
      disabled={isDisabled}
      onClick={handleClick}
      {...(isFormSubmitPrevented ? { type: 'button' } : {})}
    >
      {children}
    </button>
  );
};

Button.IconPosition = IconPosition;
Button.Size = Size;
Button.StyleType = StyleType;

export {
  Button,
  IconPosition,
  Size,
  StyleType,
};
