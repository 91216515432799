import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import {
  FetchAction,
  FetchFulfilledAction,
  FetchRejectedAction,
  State,
  StaticPage,
} from './types';

const SLICE_NAME = 'staticPages';

const initialState: State = {};

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    fetch: (state, action: FetchAction) => {
      const { key } = action.payload;
      state[key] = RemoteRecordStateProvider.getRequested<StaticPage>();
    },

    fetchFulfilled: (state, action: FetchFulfilledAction) => {
      const { staticPage, key } = action.payload;
      state[key] = RemoteRecordStateProvider.getFulfilled<StaticPage>(staticPage);
    },

    fetchRejected: (state, action: FetchRejectedAction) => {
      const { error, key } = action.payload;
      state[key] = RemoteRecordStateProvider.getRejected<StaticPage>(error);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
