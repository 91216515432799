import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import { State, SubscribeAction, SubscribeRejectedAction } from './types';

const SLICE_NAME = 'newsletter';

const initialState: State = RemoteRecordStateProvider.getDefault();

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subscribe: (state, action: SubscribeAction) => {
      return RemoteRecordStateProvider.getRequested();
    },
    subscribeFulfilled: () => {
      return RemoteRecordStateProvider.getFulfilled();
    },
    subscribeRejected: (state, action: SubscribeRejectedAction) => {
      return RemoteRecordStateProvider.getRejected(action.payload);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
