import React, { ComponentType, ReactElement, useEffect, useState } from 'react';
import { APP_TITLE } from 'dde-app/app/components/App/App';
import { Head } from 'dde-app/app/components/App/Head';
import { isServer } from 'dde-app/common/utils/server';
import { Skeleton } from 'dde-app/map/components/MapSection/Skeleton';
import { EventInfo } from '../EventInfo';
import { HowTo } from '../HowTo';
import { PhotoContest } from '../PhotoContest';
import { Subscriptions } from '../Subscriptions';
import { Component } from './types';

enum ElementId {
  EVENT_INFO = 'element-id-event-info',
  MAP = 'element-id-map',
  PHOTO_CONTEST = 'element-id-photo-contest',
}

let MapSection: ComponentType<any> = () => (
  <Skeleton />
);

const HomePage: Component = (): ReactElement => {
  const [key, setKey] = useState('map-placeholder');

  useEffect(
    () => {
      if (!isServer) {
        import('dde-app/map/components/MapSection')
          .then((mapModule) => {
            MapSection = mapModule.MapSection;
            setKey('map-loaded');
          });
      }
    },
  );

  return (
    <div className="Home">
      <Head title={APP_TITLE} />
      <HowTo />
      <Subscriptions className="container" />
      <MapSection key={key} />
      <EventInfo />
      <PhotoContest />
    </div>
  );
};

export {
  ElementId,
  HomePage,
};
