/* @flow */

import { AxiosError } from 'axios';
import { Error, ParsedValidationErrors, ValidationErrors } from 'dde-app/common/types';
import { logger } from 'dde-app/common/utils/logger/logger';
import { LoggerExtras } from 'dde-app/common/utils/logger/types';

const ERROR_KEYS = {
  DUPLICATED_EMAIL: 'duplicated_email',
  FILE_TOO_LARGE: 'file_too_large',
  INVALID_EMAIL: 'invalid_email',
  INVALID_IMAGE: 'invalid_image',
  INVALID_LATITUDE: 'invalid_latitude',
  INVALID_LONGITUDE: 'invalid_longitude',
  NOT_EMPTY: 'not_empty',
  STRING_REQUIRED: 'string_required',
  TOO_MANY_FILES: 'too_many_files',
};

const ERROR_TEXT_KEYS = {
  [ERROR_KEYS.NOT_EMPTY]: 'notEmpty',
  [ERROR_KEYS.INVALID_EMAIL]: 'invalidEmail',
  [ERROR_KEYS.DUPLICATED_EMAIL]: 'duplicateEmail',
  [ERROR_KEYS.FILE_TOO_LARGE]: 'fileTooLarge',
  [ERROR_KEYS.INVALID_IMAGE]: 'invalidImage',
  [ERROR_KEYS.INVALID_LONGITUDE]: 'invalidLng',
  [ERROR_KEYS.INVALID_LATITUDE]: 'invalidLat',
  [ERROR_KEYS.STRING_REQUIRED]: 'stringRequired',
  [ERROR_KEYS.TOO_MANY_FILES]: 'tooManyFiles',
};

const getFieldErrorMessage = (errors: string [] = []): string => {
  const errorsCopy = [...errors];
  const firstMessageIndex = errorsCopy.findIndex((error: string): boolean => error === ERROR_KEYS.NOT_EMPTY);
  const firstMessage = errorsCopy.splice(firstMessageIndex, 1);

  return [...firstMessage, ...errorsCopy].map((error: string): string => ERROR_TEXT_KEYS[error])[0];
};

const parseValidationErrors = (validationErrors: ValidationErrors): ParsedValidationErrors => (
  Object.keys(validationErrors).reduce(
    (parsedValidationErrors: ParsedValidationErrors, fieldName: string): ParsedValidationErrors => ({
      ...parsedValidationErrors,
      [fieldName]: getFieldErrorMessage(validationErrors[fieldName]?.message),
    }),
    {},
  )
);

const parseError = (error: AxiosError): Error => {
  const statusCode = error?.response?.data?.statusCode;
  const message = error?.response?.data?.message;
  const validationErrors = error?.response?.data?.errors;

  return {
    message,
    statusCode,
    validationErrors,
  };
};

const handleError = (
  message: string,
  extras: LoggerExtras,
  ignoreNetwork = true,
): void => {
  if (!extras.error) {
    return;
  }

  if (ignoreNetwork && extras.error.isAxiosError) {
    return;
  }

  logger.error(message, extras);
};

export {
  ERROR_KEYS,
  ERROR_TEXT_KEYS,
  handleError,
  parseError,
  parseValidationErrors,
};
