import React  from 'react';
import classNames from 'classnames';
import { Error } from 'dde-app/common/components/Form/common/Error';
import { Component, Props } from './types';
import './TextInput.scss';

enum Type {
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXT = 'text',
}

const TextInput: Component = ({
  className,
  defaultValue,
  error = null,
  isDisabled = false,
  isRequired,
  name,
  placeholder = '',
  register,
  type,
  validationPattern,
}: Props) => {
  const rootClassName = classNames(
    'TextInput',
    className,
  );

  return (
    <div className={rootClassName}>
      <input
        {...(register
          ? register(name, { pattern: validationPattern, required: isRequired })
          : {})
        }
        className="TextInput-Input"
        defaultValue={defaultValue}
        disabled={isDisabled}
        placeholder={placeholder}
        type={type}
      />
      {error && <Error>{error}</Error>}
    </div>
  );
};

TextInput.Type = Type;

export {
  TextInput,
  Type,
};
