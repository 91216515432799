import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LatLngTuple } from 'leaflet';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { VotingFormData } from 'dde-app/contest/components/Step2/VotingForm';
import { FetchResponseBody } from './VoteConnector.types';

class VoteConnector {
  static vote(
    flightId: string,
    {
      city,
      email,
      firstName,
      lastName,
      postalCode,
      streetAndNumber,
    }: VotingFormData,
    location: LatLngTuple,
  ): AxiosRequestConfig {
    return RequestConfig.post(
      `${getConfig('url.api')}/guess/add`,
      {
        address: `${streetAndNumber}, ${postalCode}, ${city}`,
        email,
        firstName,
        flightUuid: flightId,
        lat: location[0],
        lon: location[1],
        secondName: lastName,
      },
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): any {
    const { data } = response;

    return {
      data,
    };
  }
}

export {
  VoteConnector,
};
