import React from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './DataPair.scss';

const DataPair: Component = ({
  className,
  label,
  data,
}: Props): React.ReactElement => {
  const rootClassName = classNames(
    'DataPair text-center',
    className,
  );
  return (
    <div className={rootClassName}>
      <span className="DataPair-Label">{label}</span>
      <span className="DataPair-Data">{data}</span>
    </div>
  );
};

export {
  DataPair,
};
