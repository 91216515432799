import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { getDuration, getProgress } from 'dde-app/contest/redux/flight/slice';
import { FlightData } from 'dde-app/contest/redux/flight/types';
import { getContestStatus } from 'dde-app/contest/utils/contest';
import { FlightStatus, getAltitudeFromPositions, getDistance } from 'dde-app/contest/utils/flight';
import { FetchResponseBody } from './FlightConnector.types';

class FlightConnector {
  static fetch(): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/flight/current`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): FlightData {
    const {
      distance,
      landedAt,
      landedPoint,
      number,
      positions,
      status,
      tookOffAt,
      uuid,
    } = response.data;

    return {
      altitude: getAltitudeFromPositions(positions),
      distance: getDistance(distance),
      duration: getDuration(new Date(tookOffAt).getTime()),
      landedAt: landedAt || null,
      landedPoint: landedPoint ? [landedPoint.lat, landedPoint.lon] : null,
      number: number,
      path: Array.isArray(positions) && positions.map(position => ([position.point.lat, position.point.lon])),
      progress: status === FlightStatus.LANDED ? 100 : getProgress(new Date(tookOffAt).getTime()),
      status: getContestStatus(status, landedAt),
      tookOffAt: tookOffAt,
      uuid: uuid,
    };
  }
}

export {
  FlightConnector,
};
