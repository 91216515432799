import React, { ReactElement, useMemo } from 'react';
import Parser from 'react-jsx-parser';
import { matchPath, useLocation } from 'react-router';
import classNames from 'classnames';
import BalloonImg from 'dde-app/app/assets/balloon.png';
import { useFlightData } from 'dde-app/contest/hooks/useFlightData';
import { ContestStatus, isContestLive } from 'dde-app/contest/utils/contest';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { useStaticBlock } from 'dde-app/staticBlocks/hooks/useStaticBlock';
import { ContestStatus as ContestStatusComponent } from './ContestStatus';
import { NavBar } from './NavBar';
import { Component, Props } from './';
import './Header.scss';

const STATIC_BLOCK_KEY = 'header';

const Header: Component = ({ onScrollToClick, t }: Props): ReactElement | null => {
  const location = useLocation();
  const isHomePage = !!matchPath(
    location.pathname,
    {
      exact: true,
      path: '/',
    },
  );
  const flightData = useFlightData();
  const contestStatus = flightData.isFulfilled
    ? flightData.data.status
    : ContestStatus.INACTIVE;
  const staticBlock = useStaticBlock(STATIC_BLOCK_KEY);

  const text = useMemo(
    () => ({
      balloonImgAlt: t('Photo of DayDeal Balloon'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'Header',
    {
      'Header--WithHero': isHomePage,
    },
  );

  if (!staticBlock?.data) {
    return null;
  }

  return (
    <header className={rootClassName}>
      <NavBar onScrollToClick={onScrollToClick} />

      {isHomePage && (
        <div className="Hero container">
          <div className="Hero-ContentWrapper">
            <div className="Hero-Content">
              <Parser jsx={staticBlock.data.content} />

              <ContestStatusComponent
                isVisible={isContestLive(contestStatus)}
                onScrollToClick={onScrollToClick}
              />
            </div>
          </div>

          <div className="Hero-ImageWrapper">
            <img
              alt={text.balloonImgAlt}
              className="Hero-Image Hero-Image--Animated"
              src={BalloonImg}
            />
          </div>
        </div>
      )}
    </header>
  );
};

const ComposedHeader = withTranslation(Header);

export {
  ComposedHeader as Header,
  Header as HeaderComponent,
};
