import { LanguageCode } from 'dde-app/i18n/utils/languageCode';
import { name, version } from '../../../package.json';
import envConfig from './env';

export default Object.freeze({
  appName: name,
  languages: {
    available: [
      {
        code: LanguageCode.DE,
        label: 'Deutsch',
      },
    ],
    default: LanguageCode.DE,
    fallback: LanguageCode.EN,
  },
  version,
  ...envConfig,
});
