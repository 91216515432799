import { AxiosRequestConfig } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';

class NewsletterConnector {
  static subscribe(
    email: string,
    subscriptions: Array<string> = [],
    lang: string,
  ): AxiosRequestConfig {
    return RequestConfig.post(
      `${getConfig('url.api')}/newsletter`,
      {
        email,
        lang,
        subscriptions,
      },
    );
  }
}

export {
  NewsletterConnector,
};
