import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { fetch } from 'dde-app/staticPages/redux/actions';
import { getStaticPage } from 'dde-app/staticPages/redux/selectors';
import { StaticPage } from 'dde-app/staticPages/redux/types';

type UseStaticPageReturn = RemoteRecord<StaticPage>;

const useStaticPage = (key: string): UseStaticPageReturn => {
  const dispatch = useAppDispatch();

  const staticBlock = useAppSelector(
    useCallback(
      state => getStaticPage(state, key),
      [key],
    ),
  );

  useEffect(
    () => dispatch(fetch({ key })),
    [dispatch, key],
  );

  return staticBlock;
};

export {
  useStaticPage,
};
export type {
  UseStaticPageReturn,
};
