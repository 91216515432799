import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { VoteConnector } from 'dde-app/contest/api/VoteConnector';
import { getFlightData } from 'dde-app/contest/redux/flight/selectors';
import { VoteAction } from 'dde-app/contest/redux/vote/types';
import { subscribe } from 'dde-app/newsletter/redux/actions';
import { vote, voteFulfilled, voteRejected } from './actions';

function* voteSaga(action: VoteAction): Generator<any, any, any> {
  try {
    const { formData, location } = action.payload;
    const { flightId } = yield select(getFlightData);

    yield call(
      requestApiSaga,
      VoteConnector.vote(flightId, formData, location),
    );

    yield put(
      subscribe({
        email: formData.email,
        subscriptions: formData.subscriptions,
      }),
    );

    yield put(
      voteFulfilled(),
    );

    action.payload.callback?.();
  } catch (error) {
    const parsedError = parseError(error);

    yield put(
      voteRejected(parsedError),
    );

    yield put(
      reportError({
        error,
        message: action.type,
      }),
    );

    action.payload.callback?.(parsedError);
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(vote.type, voteSaga),
  ]);
}

export {
  saga,
};
