import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import hoistStatics from 'hoist-non-react-statics';
import { analyzePageView } from 'dde-app/analytics/redux/actions';
import { ANALYTICS_STATUS_CODE } from 'dde-app/analytics/utils/analytics';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { getDisplayName } from 'dde-app/common/utils/getDisplayName';

const withAnalytics = <P extends any>(
  Component: React.ComponentType<P>,
  pageType: string,
  getRemoteRecords?: (props: P) => Array<RemoteRecord<any>>,
  getLoadedData?: (props: P) => Record<string, any>,
  failedPageType: string = pageType,
): React.ComponentType<P> => {
  const WithAnalytics = (props: P) => {
    const [isCallbackTriggered, setIsCallbackTriggered] = useState(false);
    const {
      pathname,
      search,
      hash,
    } = useLocation();
    const url = `${pathname}${search}${hash}`;

    const records = getRemoteRecords ? getRemoteRecords(props) : [];
    const dispatch = useDispatch();

    const isLoaded = records.filter((record: RemoteRecord<any>) => record.isFulfilled).length === records.length;
    const hasFailed = records.filter((record: RemoteRecord<any>) => record.isRejected).length > 0;

    useEffect(
      () => {
        setIsCallbackTriggered(false);
      },
      [url],
    );

    useEffect(
      () => {
        if (!isLoaded || isCallbackTriggered) {
          return;
        }
        // @ts-ignore
        const { contentPageType, ...data } = getLoadedData ? getLoadedData(props) : {};
        dispatch(
          analyzePageView(
            {
              ...data,
              // @ts-ignore
              page: {
                // @ts-ignore
                ...data.page,
                type: contentPageType || pageType,
              },
              server: {
                // @ts-ignore
                ...data.server,
                code: ANALYTICS_STATUS_CODE.loaded,
              },
            },
          ),
        );
        setIsCallbackTriggered(true);
      },
      [dispatch, isCallbackTriggered, isLoaded, props],
    );

    useEffect(
      () => {
        if (!hasFailed || isCallbackTriggered) {
          return;
        }
        dispatch(
          analyzePageView(
            {
              // @ts-ignore
              page: {
                type: failedPageType,
              },
              server: {
                code: ANALYTICS_STATUS_CODE.failed,
              },
            },
          ),
        );
        setIsCallbackTriggered(true);
      },
      [dispatch, hasFailed, isCallbackTriggered],
    );

    return <Component {...props} />;
  };

  WithAnalytics.displayName = `withAnalytics(${getDisplayName(Component)})`;
  hoistStatics(WithAnalytics, Component);

  return WithAnalytics;
};

export { withAnalytics };
