import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { OgTags } from 'dde-app/ogTags/redux/types';
import { FetchResponseBody, OgTag } from './OgTagsConnector.types';

class OgTagsConnector {
  static fetch(): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/og-tag`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): OgTags {
    const {
      data,
    } = response;

    return Array.isArray(data)
      ? data.map((ogTag: OgTag) => ({
        content: ogTag.value,
        property: `og:${ogTag.name}`,
      }))
      : [];
  }
}

export {
  OgTagsConnector,
};
