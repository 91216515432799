import React, { ReactElement } from 'react';
import classNames from 'classnames';
import LogoImg from 'dde-app/common/assets/logo.svg';
import { Component, Props } from './';
import './Logo.scss';

const Logo: Component = ({ className }: Props): ReactElement => (
  <img
    alt="DayDeal"
    className={classNames(
      'Logo',
      className,
    )}
    src={LogoImg}
  />
);

export {
  Logo,
};
