import React, { ReactElement } from 'react';
import Parser from 'react-jsx-parser';
import { useParams } from 'react-router-dom';
import { APP_TITLE } from 'dde-app/app/components/App/App';
import { Head } from 'dde-app/app/components/App/Head';
import { NotFoundPage } from 'dde-app/common/components/NotFoundPage';
import { useStaticPage } from 'dde-app/staticPages/hooks/useStaticPage';
import { Component, Props } from './';
import './StaticPage.scss';

const StaticPage: Component = ({ staticContext }: Props): ReactElement => {
  const { key }: { key: string } = useParams();
  const staticPage = useStaticPage(key);

  if (staticPage?.isWaiting) {
    return null;
  }

  if (!staticPage.data) {
    return <NotFoundPage staticContext={staticContext} />;
  }

  const { data } = staticPage;
  const title = `${APP_TITLE} | ${data.title}`;

  return (
    <>
      <Head title={title} />

      <div className="StaticPage container">
        <div className="StaticPage-Wrapper">
          <h1 className="StaticPage-Title">
            {data.title}
          </h1>

          <div className="StaticPage-Content">
            <Parser jsx={data.content} />
          </div>
        </div>
      </div>
    </>
  );
};

export {
  StaticPage,
};
