import { createAction } from '@reduxjs/toolkit';
import { AnalyzeEventAction, AnalyzePageViewAction } from './types';

const analyzeEvent = createAction<AnalyzeEventAction>('analytics/analyzeEvent');
const analyzePageView = createAction<AnalyzePageViewAction>('analytics/analyzePageView');

export {
  analyzeEvent,
  analyzePageView,
};

