import { AxiosRequestConfig } from 'axios';

class RequestConfig {
  static get(url: string, config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      method: 'get',
      url,
    };
  }

  static post(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      data,
      method: 'post',
      url,
    };
  }

  static put(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      data,
      method: 'put',
      url,
    };
  }

  static patch(url: string, data: any, config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      data,
      method: 'patch',
      url,
    };
  }

  static delete(url: string, config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...config,
      method: 'delete',
      url,
    };
  }
}

export {
  RequestConfig,
};
