import { createFetchStaticPageAction } from 'src/server/actions/createFetchStaticPageAction';
import { metrics } from 'src/server/metrics';
import { LoadDataFunctionType } from 'src/server/types';

const APP_MODULE = 'static_page';

const loadData: LoadDataFunctionType = async (handleAction, match): Promise<any> => {
  metrics.increment(`${APP_MODULE}.request.count`);

  const {
    params: {
      key,
    },
  } = match;

  if (!key) {
    return;
  }

  return handleAction(
    createFetchStaticPageAction(key),
    {
      action: 'fetchStaticPage',
      appModule: APP_MODULE,
      failureMessage: `Failed to fetch static page with key=${key}`,
      successMessage: `Successfully fetched static page with key=${key}`,
    },
  );
};

export {
  loadData,
};
