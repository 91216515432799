import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import { generateFakeData } from 'dde-app/contest/redux/flight/utils';
import { ContestStatus, getContestStatus, isContestLive } from 'dde-app/contest/utils/contest';
import { getAltitude, getDistance, getDuration, getProgress } from 'dde-app/contest/utils/flight';
import { FetchFulfilledAction, FetchRejectedAction, FlightData, State, UpdatePositionAction, UpdateStatusAction } from './types';

const SLICE_NAME = 'flight';

const initialState: State = null;

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    fakeUpdateStatus(state, action: PayloadAction<ContestStatus>) {
      const fakeData = generateFakeData(action.payload);
      return RemoteRecordStateProvider.getFulfilled<FlightData>({ ...fakeData });
    },
    fetch: () => {
      return RemoteRecordStateProvider.getRequested<FlightData>();
    },
    fetchFulfilled: (state, action: FetchFulfilledAction) => {
      return RemoteRecordStateProvider.getFulfilled<FlightData>(action.payload);
    },
    fetchRejected: (state, action: FetchRejectedAction) => {
      return RemoteRecordStateProvider.getRejected<FlightData>(action.payload);
    },
    updatePosition(state, action: UpdatePositionAction) {
      if (!state?.data) {
        return state;
      }
      const {
        status,
      } = state.data;
      let {
        altitude,
        distance,
        duration,
        progress,
        path,
      } = state.data;

      if (isContestLive(status)) {
        const startTime = new Date(state?.data?.tookOffAt).getTime() || Date.now();
        altitude = getAltitude(action.payload.altitude);
        distance = getDistance(action.payload.distance);
        duration = getDuration(startTime);
        progress = getProgress(startTime);

        if (Array.isArray(state.data.path)) {
          path = state.data.path.concat([[action.payload.point.lat, action.payload.point.lon]]);
        }
      }

      state.data.altitude = altitude;
      state.data.distance = distance;
      state.data.duration = duration;
      state.data.path = path;
      state.data.progress = progress;
    },
    updateStatus(state, action: UpdateStatusAction) {
      if (!state?.data) {
        return state;
      }
      let {
        duration,
        progress,
        landedAt,
        landedPoint,
      } = state.data;
      const status = getContestStatus(action.payload.status, landedAt);

      if (status === ContestStatus.PREPARING_FOR_TAKE_OFF) {
        return initialState;
      }

      if ([ContestStatus.ACTIVE, ContestStatus.ACTIVE_NO_VOTING].includes(status)) {
        const startTime = new Date(state.data.tookOffAt).getTime() || Date.now();
        duration = getDuration(startTime);
        progress = getProgress(startTime);
      } else if (status === ContestStatus.PREANNOUNCEMENT) {
        landedPoint = action.payload.landedPoint ? [action.payload.landedPoint.lat, action.payload?.landedPoint.lon] : null;
        landedAt = action.payload.landedAt;
        progress = 100;
      }

      state.data.status = status;
      state.data.duration = duration;
      state.data.progress = progress;
      state.data.landedPoint = landedPoint;
      state.data.landedAt = landedAt;
    },
  },
});

export {
  getDuration,
  getProgress,
  slice,
  SLICE_NAME,
};
