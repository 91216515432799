import { FieldError } from 'react-hook-form';

interface ErrorText {
  fieldRequired: string,
}

interface ErrorEmailText extends ErrorText {
  errorEmailPattern: string,
}

interface ErrorPostalCodeText extends ErrorText {
  errorPostalCodePattern: string,
}

const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;
const POSTAL_CODE_PATTERN = /^([0-9]{4})$/;

const inputRule = (
  error: FieldError,
  text: ErrorText,
): string | null => {
  if (error?.type === 'required') {
    return text.fieldRequired;
  }

  if (error?.type === 'manual') {
    return error?.message;
  }

  return null;
};

const emailRule = (
  error: FieldError,
  text: ErrorEmailText,
): string | null => {
  if (error?.type === 'pattern') {
    return text.errorEmailPattern;
  }

  return inputRule(error, text);
};

const postalCodeRule = (
  error: FieldError,
  text: ErrorPostalCodeText,
): string | null => {
  if (error?.type === 'pattern') {
    return text.errorPostalCodePattern;
  }

  return inputRule(error, text);
};

export {
  EMAIL_PATTERN,
  emailRule,
  inputRule,
  POSTAL_CODE_PATTERN,
  postalCodeRule,
};
