import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { getSubmitPhotoContest } from 'dde-app/photos/photoContest/redux/selectors';

type UseGetSubmitPhotoContestDataReturn = RemoteRecord<any> | null;

const useGetSubmitPhotoContestData = (): UseGetSubmitPhotoContestDataReturn => useAppSelector(getSubmitPhotoContest);

export {
  useGetSubmitPhotoContestData,
};
