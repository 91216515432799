import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { fetch } from 'dde-app/ogTags/redux/actions';
import { getOgTags } from 'dde-app/ogTags/redux/selectors';
import { OgTags } from 'dde-app/ogTags/redux/types';

type UseOgTagsReturn = RemoteRecord<OgTags | null>;

const useOgTags = (): UseOgTagsReturn => {
  const dispatch = useAppDispatch();

  const ogTags = useAppSelector(
    useCallback(
      state => getOgTags(state),
      [],
    ),
  );

  useEffect(
    () => dispatch(fetch()),
    [dispatch],
  );

  return ogTags;
};

export {
  useOgTags,
};
export type {
  UseOgTagsReturn,
};

