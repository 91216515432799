import React, { ReactElement, useMemo } from 'react';
import arrowBottom from 'dde-app/common/assets/arrow-bottom.jpg';
import arrowRight from 'dde-app/common/assets/arrow-right.jpg';
import map from 'dde-app/common/assets/icons/icon-map.svg';
import paperplane from 'dde-app/common/assets/icons/icon-paperplane.svg';
import trophy from 'dde-app/common/assets/icons/icon-trophy.svg';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './';
import './HowTo.scss';

const HowTo: Component = ({ t }: Props): ReactElement => {
  const text = useMemo(
    () => ({
      subtitle1: t('Get notified about THE upcoming flight'),
      subtitle2: t('Guess the location of landing'),
      subtitle3: t('Wait for announcing a winner!'),
      text1: t('Subscribe to our Balloon newsletter or turn on notifications in browser to get informed when flight start.'),
      text2: t('Balloon flight will take approximately 90 minutes. The challenge is to guess the location of balloon landing. '),
      text3: t('After flight is finished person who pointed closest location of landing will win main prize.'),
      title: t('How to enter:'),
    }),
    [t],
  );

  return (
    <section className="HowTo">
      <div className="container">
        <h2 className="HowTo-Title text-center">{text.title}</h2>
        <div className="row justify-content-md-center">
          <div className="HowTo-ContentBox col-12 col-md-5 col-lg-3">
            <img
              alt={'paperplane icon'}
              className="HowTo-Icon"
              src={paperplane}
            />
            <h4 className="HowTo-Subtitle">{text.subtitle1}</h4>
            <span className="HowTo-Text">{text.text1}</span>
          </div>
          <div className="HowTo-ArrowIconBox col-12 col-lg-1">
            <img
              alt={'arrow icon'}
              className="HowTo-ArrowIcon HowTo-ArrowIcon--Bottom"
              src={arrowBottom}
            />
            <img
              alt={'arrow icon'}
              className="HowTo-ArrowIcon HowTo-ArrowIcon--Right"
              src={arrowRight}
            />
          </div>
          <div className="HowTo-ContentBox col-12 col-md-5 col-lg-3">
            <img
              alt={'map icon'}
              className="HowTo-Icon"
              src={map}
            />
            <h4 className="HowTo-Subtitle">{text.subtitle2}</h4>
            <span className="HowTo-Text">{text.text2}</span>
          </div>
          <div className="HowTo-ArrowIconBox col-12 col-lg-1">
            <img
              alt={'arrow icon'}
              className="HowTo-ArrowIcon HowTo-ArrowIcon--Bottom"
              src={arrowBottom}
            />
            <img
              alt={'arrow icon'}
              className="HowTo-ArrowIcon HowTo-ArrowIcon--Right"
              src={arrowRight}
            />
          </div>
          <div className="HowTo-ContentBox col-12 col-md-5 col-lg-3">
            <img
              alt={'trophy icon'}
              className="HowTo-Icon"
              src={trophy}
            />
            <h4 className="HowTo-Subtitle">{text.subtitle3}</h4>
            <span className="HowTo-Text">{text.text3}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

const ComposedHowTo = withTranslation(HowTo);

export {
  ComposedHowTo as HowTo,
  HowTo as HowToComponent,
};
