import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import SwiperCore, { Lazy } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import iconClose from 'dde-app/common/assets/icons/icon-close.svg';
import arrowLeft from 'dde-app/common/assets/icons/icon-slider-left.svg';
import arrowRight from 'dde-app/common/assets/icons/icon-slider-right.svg';
import { Button } from 'dde-app/common/components/Button';
import { Link } from 'dde-app/common/components/Link';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './types';
import './Slider.scss';

const EMPTY_NAME_LABEL = 'DayDeal';

const Slider: Component = ({
  className,
  galleryPhotos,
  slideIndex,
  onToggleSlider,
  isSubpageButtonDisplayed = false,
  t,
}: Props) => {
  const rootClassName = classNames(
    'Slider',
    className,
  );

  const text = useMemo(
    () => ({
      buttonText: t('view full gallery'),
    }),
    [t],
  );
  const [swiperRef, setSwiperRef] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(slideIndex);

  const onSlideChange = () => {
    setCurrentIndex(swiperRef?.realIndex);
  };

  SwiperCore.use([Lazy]);

  const goNext = () => swiperRef && swiperRef.slideNext();
  const goPrev = () => swiperRef && swiperRef.slidePrev();

  if (!Array.isArray(galleryPhotos) || !galleryPhotos.length) {
    return null;
  }

  return (
    <div className={rootClassName}>
      <div className="Slider-SliderContainer container">
        <Swiper
          className="Slider-Swiper"
          initialSlide={slideIndex || 0}
          lazy={true}
          onSlideChange={onSlideChange}
          onSwiper={setSwiperRef}
        >
          {galleryPhotos.map((photo) => (
            <SwiperSlide className="Slider-SlideWrapper Slider-Slide" key={photo.uuid}>
              <div className="Slider-Slide">
                <img
                  className="Slider-SlidePhoto swiper-lazy"
                  data-src={photo.filePathLarge || photo.filePath}
                />
                <div className="Slider-loader swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                <span className="Slider-SlideText">{photo.userName || EMPTY_NAME_LABEL}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {isSubpageButtonDisplayed && (
          <Link
            className="Slider-SubpageButton"
            url={'/photo-contest'}
          >
            <Button
              styleType={Button.StyleType.LIGHT}
              size={Button.Size.DEFAULT}
            >
              {text.buttonText}
            </Button>
          </Link>
        )}
        <img alt="close" className="Slider-CloseButton" src={iconClose} onClick={onToggleSlider(false)} />
        {(currentIndex !== 0) && <img alt="arrow-left" className="Slider-ArrowLeft" src={arrowLeft} onClick={goPrev} />}
        {(currentIndex !== galleryPhotos.length - 1) && <img alt="arrow-right" className="Slider-ArrowRight" src={arrowRight} onClick={goNext} />}
      </div>
    </div>
  );
};

const ComposedSlider = withTranslation(Slider);

export {
  ComposedSlider as Slider,
  Slider as SliderComponent,
};
