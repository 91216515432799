import React, { ReactElement } from 'react';
// @ts-ignore
import PusherComponent, { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
import { isServer } from 'dde-app/common/utils/server';
import { getConfig } from 'dde-app/config/utils/config';
import { useUpdatePosition } from 'dde-app/contest/hooks/useUpdatePosition';
import { useUpdateStatus } from 'dde-app/contest/hooks/useUpdateStatus';
import { UpdatePositionPayload, UpdateStatusPayload } from 'dde-app/contest/redux/flight/types';

//Pusher.logToConsole = true; //uncomment to see pusher events in console
const CHANNEL = 'currentFlight';
const EVENTS = {
  POSITION_UPDATED: 'position.updated',
  STATUS_UPDATED: 'status.updated',
};

if (!isServer) {
  const pusherClient = new Pusher(
    getConfig('pusher.appKey'),
    {
      cluster: getConfig('pusher.cluster'),
    },
  );

  setPusherClient(pusherClient);
}

const PusherReceiver = (): ReactElement | null => {
  const updatePosition = useUpdatePosition();
  const updateStatus = useUpdateStatus();

  return (<>
    <PusherComponent
      channel={CHANNEL}
      event={EVENTS.STATUS_UPDATED}
      onUpdate={(payload: UpdateStatusPayload) => updateStatus(payload)}
    />
    <PusherComponent
      channel={CHANNEL}
      event={EVENTS.POSITION_UPDATED}
      onUpdate={(payload: UpdatePositionPayload) => updatePosition(payload)}
    />
  </>);
};

export {
  PusherReceiver,
};
