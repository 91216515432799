import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import isObject from 'lodash.isobject';
import { LoggerLevel } from '../logger';
import { Logger, LoggerExtras } from '../types';

const sentryLogger: Logger = {
  [LoggerLevel.DEBUG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(Severity.Debug, message, extras),
  [LoggerLevel.ERROR]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(Severity.Error, message, extras),
  [LoggerLevel.INFO]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(Severity.Info, message, extras),
  [LoggerLevel.LOG]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(Severity.Log, message, extras),
  [LoggerLevel.WARN]: (
    message: string,
    extras: LoggerExtras = {},
  ) => captureMessage(Severity.Warning, message, extras),
};

const captureMessage = (
  level: Severity,
  message: string,
  extras: LoggerExtras = {},
): void => {
  const { fingerprint, ...otherExtras } = extras;
  const { error } = otherExtras;

  Sentry.withScope(scope => {
    scope.setLevel(level);

    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }

    if (isObject(otherExtras)) {
      scope.setExtras(otherExtras);
    }

    if (error && level === Severity.Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(message);
    }
  });
};

export {
  sentryLogger,
};
