import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { Reward as RewardReduxType } from 'dde-app/contest/redux/reward/types';
import { FetchResponseBody } from './RewardConnector.types';

class RewardConnector {
  static fetch(): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/reward/current`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): RewardReduxType {
    const { data } = response;

    return {
      description: data.description,
      flightId: data.uuid,
      url: data.url,
    };
  }
}

export {
  RewardConnector,
};
