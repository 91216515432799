import React, { ReactElement } from 'react';
import { Button } from 'src/client/common/components/Button';
import { Checkbox } from 'src/client/common/components/Form/Checkbox';
import { TextInput } from 'src/client/common/components/Form/TextInput';
import { ProgressBar } from 'src/client/common/components/ProgressBar';
import back from 'dde-app/common/assets/icons/icon-arrow-back.svg';
import forward from 'dde-app/common/assets/icons/icon-arrow-forward.svg';

const StyleGuide = (): ReactElement => (
  <section className="StyleGuide container">
    <h2>ProgressBar</h2>
    <div>
      <ProgressBar
        value={30}
      />
    </div>

    <h2 style={{ marginTop: '80px' }}>Form Elements</h2>
    <div>
      <h3>text input text</h3>
      <TextInput
        error="Error"
        placeholder="placeholder"
        type={TextInput.Type.TEXT}
      />

      <h3 style={{ marginTop: '50px' }}>text input password</h3>
      <TextInput
        error="Error"
        placeholder="placeholder"
        type={TextInput.Type.PASSWORD}
      />

      <h3 style={{ marginTop: '50px' }}>text input email</h3>
      <TextInput
        error="Error"
        placeholder="placeholder"
        type={TextInput.Type.EMAIL}
      />
    </div>

    <div style={{ marginTop: '80px' }}>
      <h3 style={{ marginTop: '50px' }}>Checkbox</h3>
      <Checkbox
        error="Error"
        label="I read and understand terms and conditios"
        defaultChecked={false}
        onChange={(isChecked) => console.log('Checked: ', isChecked)}
        isRequired={true}
      />
      <h3 style={{ marginTop: '50px' }}>Checkbox disabled</h3>
      <Checkbox
        error="Error"
        label="I read and understand terms and conditios"
        isDisabled={true}
        defaultChecked={false}
        onChange={(isChecked) => console.log('Checked: ', isChecked)}
        isRequired={true}
      />
    </div>

    <h2 style={{ marginTop: '120px' }}>Button</h2>
    <div>
      <p>PRIMARY SMALLsize righticon</p>
      <Button
        iconPosition={Button.IconPosition.RIGHT}
        styleType={Button.StyleType.PRIMARY}
        size={Button.Size.SMALL}
      >button abcd
        <img src={forward} /></Button>
      <br/>
      <p>SECONDARY SMALLsize</p>
      <Button
        styleType={Button.StyleType.SECONDARY}
        size={Button.Size.SMALL}
      >button abcd</Button>
      <br/>
      <p>LIGHT SMALLsize</p>
      <Button
        styleType={Button.StyleType.LIGHT}
        size={Button.Size.SMALL}
      >button abcd</Button>
      <br/>
      <p>PRIMARY defsize</p>
      <Button
        styleType={Button.StyleType.PRIMARY}
      >button abcd</Button>
      <br/>ok
      <p>SECONDARY defsize</p>
      <Button
        styleType={Button.StyleType.SECONDARY}
      >button abcd</Button>
      <br/>
      <p>LIGHT defsize</p>
      <Button
        styleType={Button.StyleType.LIGHT}
      >button abcd</Button>
      <br/>
      <p>PRIMARY LARGEsize righticon</p>
      <Button
        iconPosition={Button.IconPosition.RIGHT}
        styleType={Button.StyleType.PRIMARY}
        size={Button.Size.LARGE}
      >
        button abcd
        <img src={forward} />
      </Button>
      <br/>
      <p>SECONDARY LARGEsize</p>
      <Button
        styleType={Button.StyleType.SECONDARY}
        size={Button.Size.LARGE}
      >button abcd
      </Button>
      <br/>
      <p> LIGHT LARGEsize lefticon</p>
      <Button
        iconPosition={Button.IconPosition.LEFT}
        styleType={Button.StyleType.LIGHT}
        size={Button.Size.LARGE}
      >
        <img src={back} />
        button abcd
      </Button>
      <br/>
      <p>fullwidth disabled</p>
      <Button isFullWidth={true} isDisabled={true} >button abcd</Button>
      <br/>
    </div>
  </section>
);

export {
  StyleGuide,
};
