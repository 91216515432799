import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import photoTakingImg from 'dde-app/common/assets/photo-taking.png';
import prizeImg from 'dde-app/common/assets/prize.png';
import { Button } from 'dde-app/common/components/Button';
import { Link } from 'dde-app/common/components/Link';
import { isServer } from 'dde-app/common/utils/server';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Gallery } from 'dde-app/photos/gallery/components/Gallery/Gallery';
import { PhotoContestModal } from 'dde-app/photos/photoContest/components/PhotoContestModal';
import { Component, Props } from './types';
import './PhotoContest.scss';

const PhotoContest: Component = ({
  t,
}: Props) => {
  const text = useMemo(
    () => ({
      choosePhoto: t('choose photo'),
      paragraph: t('Snap a photo and upload it to our gallery. Each accepted photo wins a '),
      paragraphBold: t('DayDeal voucher code!'),
      photoContest: t('Photo Contest'),
      photoGallery: t('Photo Gallery'),
      photos: t('Photos'),
      submitPhotoButton: t('submit photo'),
      subtitle: t('Spotted DayDeal Balloon?'),
      viewGallery: t('view gallery'),
    }),
    [t],
  );

  !isServer && Modal.setAppElement(document.getElementById('root'));
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleModalToggle = useCallback((newModalState?: boolean) => {
    setIsModalOpen(newModalState || !isModalOpen);
  }, [
    isModalOpen,
    setIsModalOpen,
  ]);

  return (
    <section className="PhotoContest" id={ElementId.PHOTO_CONTEST}>
      <div className="container">
        <h2 className="PhotoContest-Title">{text.photoContest}</h2>
        <div className="PhotoContest-ContentWrapper row">
          <div className="col-12 col-md-4 offset-md-2 PhotoContest-ImageBox">
            <img className="PhotoContest-PhotoImage" src={photoTakingImg} alt="photo" />
          </div>
          <div className="col-12 col-md-5 col-lg-4">
            <h3 className="PhotoContest-Subtitle">{text.subtitle}</h3>
            <p className="PhotoContest-Paragraph">
              {text.paragraph}
              <span className="PhotoContest-ParagraphBold">{text.paragraphBold}</span>
            </p>
            <img className="PhotoContest-PrizeImage" src={prizeImg} alt="prize" />
            <Button isFullWidth={true} onClick={openModal}>{text.submitPhotoButton}</Button>
          </div>
        </div>
        <Gallery isHomePage={true} />
        <div className="d-flex justify-content-center">
          <Link
            className="PhotoContest-OpenGalleryButton"
            url={'/photo-contest#top'}
            >
            <Button
              styleType={Button.StyleType.LIGHT}
              size={Button.Size.DEFAULT}
              >
              {text.viewGallery}
            </Button>
          </Link>
        </div>
      </div>
      <PhotoContestModal isModalOpen={isModalOpen} onModalToggle={handleModalToggle} />
    </section>
  );
};

const ComposedPhotoContest = withTranslation(PhotoContest);

export {
  ComposedPhotoContest as PhotoContest,
  PhotoContest as PhotoContestComponent,
};
