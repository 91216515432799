import merge from 'lodash.merge';
import { all, delay, select, takeEvery } from 'redux-saga/effects';
import { analyzeEvent, analyzePageView } from 'dde-app/analytics/redux/actions';
import { getAnalyticsCommonData } from 'dde-app/analytics/redux/selectors';
import type { AnalyzeEventAction, AnalyzePageViewAction } from 'dde-app/analytics/redux/types';

const ANALYZE_EVENT_DELAY = 300;
const ANALYZE_PAGE_VIEW_DELAY = 100;

function* analyzeEventSaga(action: AnalyzeEventAction): Generator<any, any, any> {
  // @ts-ignore
  if (!window?.utagQ?.link) {
    return;
  }

  yield delay(ANALYZE_EVENT_DELAY);

  // @ts-ignore
  window.utagQ.link(action.payload);
}

function* analyzePageViewSaga(action: AnalyzePageViewAction): Generator<any, any, any> {
  // @ts-ignore
  if (!window?.utagQ?.view) {
    return;
  }

  yield delay(ANALYZE_PAGE_VIEW_DELAY);

  // @ts-ignore
  window.utagQ.view(
    merge(
      yield select(getAnalyticsCommonData),
      action.payload,
    ),
  );
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(analyzeEvent.type, analyzeEventSaga),
    takeEvery(analyzePageView.type, analyzePageViewSaga),
  ]);
}

export {
  saga,
};
