import { LatLngTuple } from 'leaflet';
import { FlightData } from 'dde-app/contest/redux/flight/types';
import { ContestStatus } from 'dde-app/contest/utils/contest';

// @ts-ignore
const fakeData: FlightData = {};

const generateFakeData = (status: ContestStatus): FlightData => {
  const path: LatLngTuple[] = [
    [51.515, -0.095],
    [51.516, -0.096],
    [51.518, -0.097],
    [51.522, -0.098],
    [51.523, -0.099],
    [51.524, -0.096],
    [51.526, -0.097],
    [51.527, -0.099],
    [51.526, -0.103],
    [51.525, -0.107],
    [51.524, -0.109]];
  if (status === ContestStatus.PREPARING_FOR_TAKE_OFF || status === ContestStatus.INACTIVE) {
    fakeData.path = [];
    status === ContestStatus.PREPARING_FOR_TAKE_OFF && fakeData.path.push([51.515, -0.095]);
    fakeData.progress = 0;
    fakeData.altitude = 0;
    fakeData.duration = {
      hours: 0,
      minutes: 0,
    };
    fakeData.distance = 0;
  } else if (status === ContestStatus.ACTIVE) {
    fakeData.path = path;
    fakeData.progress = 35;
    fakeData.altitude = 155;
    fakeData.duration = {
      hours: 0,
      minutes: 15,
    };
    fakeData.distance = 55;
  } else if (status === ContestStatus.ACTIVE_NO_VOTING) {
    fakeData.path = path;
    fakeData.progress = 80;
    fakeData.altitude = 45;
    fakeData.duration = {
      hours: 1,
      minutes: 35,
    };
    fakeData.distance = 90;
  } else if (status === ContestStatus.PREANNOUNCEMENT) {
    fakeData.path = path;
    fakeData.progress = 100;
    fakeData.altitude = 45;
    fakeData.duration = {
      hours: 1,
      minutes: 55,
    };
    fakeData.distance = 110;
  } else if (status === ContestStatus.ANNOUNCEMENT) {
    fakeData.path = path;
  }

  fakeData.tookOffAt = '2021-06-08T22:08:17.457Z';
  fakeData.status = status;
  fakeData.number = Math.floor(Math.random() * 1000);
  fakeData.uuid = `${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 10000)}`;

  return fakeData;
};

export {
  generateFakeData,
};
