const ANALYTICS_STATUS_CODE = {
  failed: '500',
  loaded: '200',
  notFound: '400',
};

const ANALYTICS_BOOLEAN = {
  false: 'n',
  true: 'y',
};

const ANALYTICS_PAGE_TYPE = {
  errorPage: 'ErrorPage',
  general: 'Daydeal Balloon',
};

const ANALYTICS_INTERACTION_ID = {
  generic: 'GE-ALL',
};

const HOT_JAR_TAG = {
  votingConfirmation: 'contest_confirmation',
  votingGuessing: 'contest_guessing',
  votingSaving: 'contest_saving',
};

const triggerHotJarRecording = (trigger: string): void => {
  // @ts-ignore
  if (window?.hj) {
    // @ts-ignore
    window.hj('trigger', trigger);
  }
};

const tagHotJarRecording = (...tags: Array<string>): void => {
  // @ts-ignore
  if (window?.hj) {
    // @ts-ignore
    window.hj('tagRecording', tags);
  }
};

export {
  ANALYTICS_BOOLEAN,
  ANALYTICS_INTERACTION_ID,
  ANALYTICS_PAGE_TYPE,
  ANALYTICS_STATUS_CODE,
  HOT_JAR_TAG,
  tagHotJarRecording,
  triggerHotJarRecording,
};
