import React, { ReactElement, useMemo } from 'react';
import liveStatusIcon from 'dde-app/common/assets/icons/icon-live-status.svg';
import { Button } from 'dde-app/common/components/Button';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './';
import './ContestStatus.scss';

const ContestStatus: Component = ({
  isVisible,
  onScrollToClick,
  t,
}: Props): ReactElement | null => {
  const text = useMemo(
    () => ({
      buttonContent: t('Play now'),
      notificationContent: t('Contest is live! Make a guess and win a reward!'),
    }),
    [t],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <div className="ContestStatus">
      <div className="ContestStatus-ContentWrapper">
        <div className="ContestStatus-Content">
          <img
            alt="Live status icon"
            className="ContestStatus-Icon"
            src={liveStatusIcon}
          />

          <span className="ContestStatus-Info">
            {text.notificationContent}
          </span>
        </div>

        <Button
          className="ContestStatus-Button"
          onClick={onScrollToClick(ElementId.MAP)}
          size={Button.Size.SMALL}
        >
          {text.buttonContent}
        </Button>
      </div>
    </div>
  );
};

const ComposedContestStatus = withTranslation(ContestStatus);

export {
  ContestStatus as ContentStatusComponent,
  ComposedContestStatus as ContestStatus,
};
