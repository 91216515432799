import { withAnalytics } from 'dde-app/analytics/hoc/withAnalytics';
import { ANALYTICS_PAGE_TYPE, ANALYTICS_STATUS_CODE } from 'dde-app/analytics/utils/analytics';
import { NotFoundPage as NotFoundPageComponent } from './NotFoundPage';

const NotFoundPage = withAnalytics(
  NotFoundPageComponent,
  ANALYTICS_PAGE_TYPE.errorPage,
  () => [],
  () => ({
    server: {
      code: ANALYTICS_STATUS_CODE.notFound,
    },
  }),
);

export {
  NotFoundPage,
  NotFoundPageComponent,
};
export * from './types';
