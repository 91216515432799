import { all, cancel, fork, take } from 'redux-saga/effects';
import { CANCEL_TASKS } from 'src/server/actions/actionTypes';
import { saga as analyticsSaga } from 'dde-app/analytics/redux/saga';
import { saga as errorSaga } from 'dde-app/common/redux/error/saga';
import { saga as contestSaga } from 'dde-app/contest/redux/saga';
import { saga as flightsSummarySaga } from 'dde-app/home/redux/saga';
import { saga as newsletterSaga } from 'dde-app/newsletter/redux/saga';
import { saga as ogTagsSaga } from 'dde-app/ogTags/redux/saga';
import { saga as gallerySaga } from 'dde-app/photos/gallery/redux/saga';
import { saga as photoContestSaga } from 'dde-app/photos/photoContest/redux/saga';
import { saga as staticBlocksSaga } from 'dde-app/staticBlocks/redux/saga';
import { saga as staticPagesSaga } from 'dde-app/staticPages/redux/saga';

function* appSaga(): Generator<any, any, any> {
  yield all([
    fork(analyticsSaga),
    fork(contestSaga),
    fork(errorSaga),
    fork(flightsSummarySaga),
    fork(newsletterSaga),
    fork(gallerySaga),
    fork(staticBlocksSaga),
    fork(staticPagesSaga),
    fork(photoContestSaga),
    fork(ogTagsSaga),
  ]);
}

function* ssrSaga(): Generator<any, any, any> {
  while (true) {
    const task = yield fork(appSaga);
    yield take(CANCEL_TASKS);
    yield cancel(task);
  }
}

export {
  appSaga,
  ssrSaga,
};
