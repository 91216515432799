import { useCallback } from 'react';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { getLanguage } from 'dde-app/i18n/redux/selectors';

const useActiveLanguage = (): string => useAppSelector(
  useCallback(
    getLanguage,
    [],
  ),
);

export {
  useActiveLanguage,
};
