import { Position } from 'dde-app/contest/api/FlightConnector.types';
import { Winner } from 'dde-app/contest/redux/winners/types';
import { Duration } from 'dde-app/contest/utils/types';

enum FlightStatus {
  TAKING_OFF = 'taking_off',
  IN_FLIGHT = 'in_flight',
  LANDING = 'landing',
  LANDED = 'landed',
  FINISHED = 'finished',
  ISSUE = 'issue',
}

const ASSUMED_FLIGHT_DURATION = 180;

const getAltitude = (altitude: number | null): number => {
  return altitude ? parseFloat(altitude.toFixed(0)) : 0;
};

const getAltitudeFromPositions = (positions: Array<Position>): number => {
  if (Array.isArray(positions) && positions.length) {
    const altitude = positions[positions.length - 1].altitude;
    return getAltitude(altitude);
  }

  return 0;
};

const getElapsedTimeInMinutes = (startTime: number): number => {
  const currentTime = Date.now();
  return (currentTime - startTime) / 1000 / 60;
};

const getDistance = (distance: number): number => {
  return parseFloat(distance.toFixed(1));
};

const getDuration = (startTime: number): Duration => {
  const elapsedTimeInMinutes = getElapsedTimeInMinutes(startTime);
  const hours = Math.trunc(elapsedTimeInMinutes / 60);
  const minutes = Math.floor(elapsedTimeInMinutes % 60);
  return {
    hours,
    minutes,
  };
};

const getProgress = (startTime: number): number => {
  return Math.floor(getElapsedTimeInMinutes(startTime) / ASSUMED_FLIGHT_DURATION * 100);
};

const getFlightWinner = (winners: Array<Winner> | null, flightNumber: number): Winner | null => {
  if (!winners || !Array.isArray(winners)) {
    return null;
  }
  const flightWinner = winners.filter(
    winner => winner.flightNumber === flightNumber,
  );

  return flightWinner ? flightWinner[0] : null;
};

export {
  ASSUMED_FLIGHT_DURATION,
  FlightStatus,
  getAltitude,
  getAltitudeFromPositions,
  getDistance,
  getDuration,
  getElapsedTimeInMinutes,
  getFlightWinner,
  getProgress,
};
