import { Error } from 'dde-app/common/types';
import { RemoteRecordStatus } from './RemoteRecord';

interface RemoteRecordState<T> {
  data: T | null;
  error: Error | null;
  status: RemoteRecordStatus | null;
}

class RemoteRecordStateProvider {
  static getDefault<T>(): RemoteRecordState<T> {
    return {
      data: null,
      error: null,
      status: null,
    };
  }

  static getRequested<T>(): RemoteRecordState<T> {
    return {
      ...RemoteRecordStateProvider.getDefault<T>(),
      status: RemoteRecordStatus.REQUESTED,
    };
  }

  static getFulfilled<T>(data: T = null): RemoteRecordState<T> {
    return {
      ...RemoteRecordStateProvider.getDefault<T>(),
      data,
      status: RemoteRecordStatus.FULFILLED,
    };
  }

  static getRejected<T>(error: Error = null): RemoteRecordState<T> {
    return {
      ...RemoteRecordStateProvider.getDefault<T>(),
      error,
      status: RemoteRecordStatus.REJECTED,
    };
  }
}

export {
  RemoteRecordStateProvider,
};
export type {
  RemoteRecordState,
};
