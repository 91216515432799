import { slice } from './slice';

export const {
  actions: {
    fakeUpdateStatus,
    fetch,
    fetchFulfilled,
    fetchRejected,
    updatePosition,
    updateStatus,
  },
} = slice;
