import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { updatePosition } from 'dde-app/contest/redux/flight/actions';

type UseUpdatePositionReturn = typeof updatePosition;

const useUpdatePosition = (): UseUpdatePositionReturn => {
  const dispatch = useAppDispatch();
  return bindActionCreators(updatePosition, dispatch);
};

export {
  useUpdatePosition,
};
export type {
  UseUpdatePositionReturn,
};
