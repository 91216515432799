import React, { useMemo } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './types';
import './ProgressBar.scss';

const ProgressBar: Component = ({
  className,
  areLabelsDisplayed = true,
  t,
  value = 0,
}: Props): React.ReactElement => {
  const text = useMemo(
    () => ({
      finish: t('Finish'),
      start: t('Start'),
      title: t('Flight progress (approximate)'),
    }),
    [t],
  );

  const rootClassName = classNames(
    'ProgressBar',
    className,
  );

  return (
    <div className={rootClassName}>
      <div className="ProgressBar-Percentage">
        <div
          className="ProgressBar-Percentage--Filled"
          style={{
            width: `${Math.min(value, 100)}%`,
          }}
        />
      </div>
      {areLabelsDisplayed && (
        <div className="ProgressBar-Bottom">
          <span className="text-uppercase">
            {text.start}
          </span>
          <span className="ProgressBar-BottomTitle">
            {text.title}
          </span>
          <span className="text-uppercase">
            {text.finish}
          </span>
        </div>
      )}
    </div>
  );
};

const ComposedProgressBar = withTranslation(ProgressBar);

export {
  ComposedProgressBar as ProgressBar,
  ProgressBar as ProgressBarComponent,
};
