import {
  CANCEL_TASKS,
} from 'src/server/actions/actionTypes';
import type {
  CancelTasksAction,
} from 'src/server/actions/types';

const cancelTasks = (): CancelTasksAction => ({
  type: CANCEL_TASKS,
});

export {
  cancelTasks,
};
