import React, { FC, ReactElement } from 'react';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import '../MapSection.scss';
import '../Map/Map.scss';
import './Skeleton.scss';

const Skeleton: FC = (): ReactElement => (
  <section className="MapSection" id={ElementId.MAP}>
    <div className="container MapSection-TitleContainer Skeleton-TitleContainer">
      <div className="Skeleton-Title" />
    </div>

    <div className="container MapSection-MapContentContainer Skeleton-MapContentContainer">
      <div className="MapSection-MapContentWrapper">
        <div className="Map Skeleton-Map" />
        <div className="MapSection-Contest Skeleton-MapContest" />
      </div>
    </div>
  </section>
);

export {
  Skeleton,
};
