import React, { ComponentType } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { Subtract } from 'utility-types';
import { getDisplayName } from 'dde-app/common/utils/getDisplayName';
import { useActiveLanguage } from 'dde-app/i18n/hooks/useActiveLanguage';
import { InjectedProps, TranslateFunction } from './';

const withTranslation = <P extends InjectedProps>(
  Component: ComponentType<P>,
): ComponentType<Subtract<P, InjectedProps>> => {
  const WithTranslation = (
    props: Subtract<P, InjectedProps>,
    context: { t: TranslateFunction },
  ) => {
    const activeLanguage = useActiveLanguage();

    const addProps = {
      activeLanguage,
      t: context.t,
    };

    return <Component {...props as P} {...addProps} />;
  };

  WithTranslation.displayName = `withTranslation(${getDisplayName(Component)})`;
  hoistStatics(WithTranslation, Component);

  WithTranslation.contextTypes = {
    t: PropTypes.func.isRequired,
  };

  return WithTranslation;
};

export { withTranslation };
