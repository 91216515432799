import { createFetchOgTagsAction } from 'src/server/actions/createFetchOgTagsAction';
import { createFetchStaticBlockAction } from 'src/server/actions/createFetchStaticBlockAction';
import { LoadDataFunctionType } from 'src/server/types';

const APP_MODULE = 'common';
const STATIC_BLOCK_KEYS = {
  header: 'header',
};

const loadData: LoadDataFunctionType = async (handleAction): Promise<any> => {
  const commonMeta = {
    appModule: APP_MODULE,
  };

  return Promise.all([
    handleAction(
      createFetchStaticBlockAction(STATIC_BLOCK_KEYS.header),
      {
        ...commonMeta,
        action: 'fetchStaticBlock',
        failureMessage: `Failed to fetch static block with key: ${STATIC_BLOCK_KEYS.header}`,
        successMessage: `Successfully fetched static block with key: ${STATIC_BLOCK_KEYS.header}`,
      },
    ),
    handleAction(
      createFetchOgTagsAction(),
      {
        ...commonMeta,
        action: 'fetchOgTags',
        failureMessage: 'Failed to fetch og tags',
        successMessage: 'Successfully fetched og tags',

      },
    ),
  ]);
};

export {
  loadData,
};
