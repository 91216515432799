import { withAnalytics } from 'dde-app/analytics/hoc/withAnalytics';
import { ANALYTICS_PAGE_TYPE } from 'dde-app/analytics/utils/analytics';
import { ElementId, HomePage as HomePageComponent } from './HomePage';
import { loadData } from './loadData';

const HomePage = withAnalytics(
  HomePageComponent,
  ANALYTICS_PAGE_TYPE.general,
);

export {
  ElementId,
  HomePage,
  HomePageComponent,
  loadData,
};
