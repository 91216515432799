import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

type UsePushReturn = typeof push;

const usePush = (): UsePushReturn => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(push, dispatch),
    [dispatch],
  );
};

export {
  usePush,
};
export type {
  UsePushReturn,
};
