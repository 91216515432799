import React from 'react';
import trashIcon from 'dde-app/common/assets/icons/icon-trash.svg';
import { Component, Props } from './types';
import './PhotoContestPreview.scss';

const PhotoContestPreview: Component = ({
  onRemovePreviewImage,
  preloadedImage,
}: Props) => (
  <>
    <div className="PhotoContestPreview">
      <img className="PhotoContestPreview-Image" src={URL.createObjectURL(preloadedImage)} />
    </div>
    <div className="PhotoContestPreview-FileInfo">
      <span className="PhotoContestPreview-FileInfo--Text">{preloadedImage.name}</span>
      <img className="PhotoContestPreview-TrashIcon" src={trashIcon} onClick={onRemovePreviewImage} />
    </div>
  </>
);


export {
  PhotoContestPreview,
};
