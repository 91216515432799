import { combineReducers } from '@reduxjs/toolkit';
import { reducer as flightReducer } from 'dde-app/contest/redux/flight/reducer';
import { SLICE_NAME as FLIGHT_SLICE_NAME } from 'dde-app/contest/redux/flight/slice';
import { reducer as rewardReducer } from 'dde-app/contest/redux/reward/reducer';
import { SLICE_NAME as REWARD_SLICE_NAME } from 'dde-app/contest/redux/reward/slice';
import { reducer as voteReducer } from 'dde-app/contest/redux/vote/reducer';
import { SLICE_NAME as VOTE_SLICE_NAME } from 'dde-app/contest/redux/vote/slice';
import { reducer as winnersReducer } from 'dde-app/contest/redux/winners/reducer';
import { SLICE_NAME as WINNERS_SLICE_NAME } from 'dde-app/contest/redux/winners/slice';

const SLICE_NAME = 'contest';

const reducer = combineReducers({
  [FLIGHT_SLICE_NAME]: flightReducer,
  [REWARD_SLICE_NAME]: rewardReducer,
  [VOTE_SLICE_NAME]: voteReducer,
  [WINNERS_SLICE_NAME]: winnersReducer,
});

export {
  reducer,
  SLICE_NAME,
};
