import { all, call, put, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { StaticBlockConnector } from 'dde-app/staticBlocks/api/StaticBlockConnector';
import { fetch, fetchFulfilled, fetchRejected } from './actions';
import { FetchAction } from './types';

function* fetchSaga(action: FetchAction): Generator<any, any, any> {
  const { key } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      StaticBlockConnector.fetch(key),
    );

    yield put(
      fetchFulfilled({
        key,
        staticBlock: StaticBlockConnector.mapResponseToState(response),
      }),
    );
  } catch (error) {
    yield put(
      fetchRejected({
        error: parseError(error),
        key,
      }),
    );
    yield put(
      reportError({
        error,
        message: action.type,
      }),
    );
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetch.type, fetchSaga),
  ]);
}

export {
  saga,
};
