import { all, call, put, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { FlightsSummaryConnector } from 'dde-app/home/api/FlightsSummaryConnector';
import { fetch, fetchFulfilled, fetchRejected } from './actions';

function* fetchSaga(): Generator<any, any, any> {
  try {
    const response = yield call(
      requestApiSaga,
      FlightsSummaryConnector.fetch(),
    );

    yield put(
      fetchFulfilled(FlightsSummaryConnector.mapResponseToState(response)),
    );
  } catch (error) {
    yield put(
      fetchRejected(parseError(error)),
    );
    yield put(
      reportError({
        error,
        message: 'flightsSummary fetchSaga',
      }),
    );
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetch.type, fetchSaga),
  ]);
}

export {
  saga,
};
