import { all, call, put, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { PhotoContestConnector } from 'dde-app/photos/photoContest/api/PhotoContestConnector';
import { submitPhotoContest, submitPhotoContestFulfilled, submitPhotoContestRejected } from './actions';
import { SubmitPhotoContestAction } from './types';

function* photoContestSaga(action: SubmitPhotoContestAction): Generator<any, any, any> {
  const {
    email,
    firstName,
    getUploadProgress,
    lastName,
    photos,
  } = action.payload;

  try {
    yield call(
      requestApiSaga,
      PhotoContestConnector.send(firstName, lastName, email, photos, getUploadProgress),
    );

    yield put(
      submitPhotoContestFulfilled(),
    );
  } catch (error) {
    yield put(
      submitPhotoContestRejected(
        parseError(error),
      ),
    );
    yield put(
      reportError({
        error,
        message: action.type,
      }),
    );
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(submitPhotoContest.type, photoContestSaga),
  ]);
}

export {
  saga,
};
