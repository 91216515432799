// @ts-ignore
import { CALLBACK_ERROR_ARGUMENT, ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { fetch, fetchFulfilled, fetchRejected } from 'dde-app/photos/gallery/redux/actions';
import { FetchAction, FetchFulfilledAction, FetchRejectedAction } from 'dde-app/photos/gallery/redux/types';

const createFetchGalleryAction = (skip?: number, limit?: number): FetchAction => ({
  ...fetch({ limit, skip }),
  [CALLBACK_ERROR_ARGUMENT]: (action: FetchRejectedAction) => action.payload,
  [ERROR_ACTION]: ({ type }: FetchRejectedAction) => type === fetchRejected.type,
  [WAIT_FOR_ACTION]: ({ type }: FetchFulfilledAction) => type === fetchFulfilled.type,
});

export {
  createFetchGalleryAction,
};
