import { Error } from 'dde-app/common/types';
import { RemoteRecordState, RemoteRecordStateProvider } from './RemoteRecordStateProvider';

enum RemoteRecordStatus { REQUESTED, FULFILLED, REJECTED }

class RemoteRecord<T> {
  state: RemoteRecordState<T>;

  constructor(state: RemoteRecordState<T> = null) {
    this.state = {
      ...RemoteRecordStateProvider.getDefault<T>(),
      ...state,
    };
  }

  get data(): T | null {
    return this.state.data;
  }

  get error(): Error | null {
    return this.state.error;
  }

  get status(): RemoteRecordStatus | null {
    return this.state.status;
  }

  get wasStarted(): boolean {
    return this.isRequested || this.isFulfilled || this.isRejected;
  }

  get isWaiting(): boolean {
    return !this.wasStarted || this.isRequested;
  }

  get isRequested(): boolean {
    return this.status === RemoteRecordStatus.REQUESTED;
  }

  get isFulfilled(): boolean {
    return this.status === RemoteRecordStatus.FULFILLED;
  }

  get isRejected(): boolean {
    return this.status === RemoteRecordStatus.REJECTED;
  }
}

export {
  RemoteRecord,
  RemoteRecordStatus,
};
export type {
  RemoteRecordState,
};
