import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { fetch } from 'dde-app/home/redux/actions';
import { getFlightsSummary } from 'dde-app/home/redux/selectors';
import { FlightsSummary } from 'dde-app/home/redux/types';

type UseFlightsSummaryReturn = RemoteRecord<FlightsSummary | null>;

const useFlightsSummary = (): UseFlightsSummaryReturn => {
  const dispatch = useAppDispatch();

  const flightsSummary = useAppSelector(
    useCallback(
      state => getFlightsSummary(state),
      [],
    ),
  );

  useEffect(
    () => {
      dispatch(fetch());
    },
    [dispatch],
  );

  return flightsSummary;
};

export {
  useFlightsSummary,
};
export type {
  UseFlightsSummaryReturn,
};

