import { slice } from './slice';

export const {
  actions: {
    clearSubmitPhotoContest,
    submitPhotoContest,
    submitPhotoContestFulfilled,
    submitPhotoContestRejected,
  },
} = slice;
