import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { i18nState } from 'redux-i18n';
import { history } from 'dde-app/common/utils/history';
import { reducer as contestReducer, SLICE_NAME as CONTEST_SLICE_NAME } from 'dde-app/contest/redux/reducer';
import { reducer as flightsSummaryReducer } from 'dde-app/home/redux/reducer';
import { SLICE_NAME as FLIGHTS_SUMMARY_SLICE_NAME } from 'dde-app/home/redux/slice';
import { reducer as newsletterReducer } from 'dde-app/newsletter/redux/reducer';
import { SLICE_NAME as NEWSLETTER_SLICE_NAME } from 'dde-app/newsletter/redux/slice';
import { reducer as ogTagsReducer } from 'dde-app/ogTags/redux/reducer';
import { SLICE_NAME as OG_TAGS_SLICE_NAME } from 'dde-app/ogTags/redux/slice';
import { reducer as galleryReducer } from 'dde-app/photos/gallery/redux/reducer';
import { SLICE_NAME as GALLERY_SLICE_NAME } from 'dde-app/photos/gallery/redux/slice';
import { reducer as photoContestReducer } from 'dde-app/photos/photoContest/redux/reducer';
import { SLICE_NAME as PHOTO_CONTEST_SLICE_NAME } from 'dde-app/photos/photoContest/redux/slice';
import { reducer as staticBlocksReducer } from 'dde-app/staticBlocks/redux/reducer';
import { SLICE_NAME as STATIC_BLOCKS_SLICE_NAME } from 'dde-app/staticBlocks/redux/slice';
import { reducer as staticPagesReducer } from 'dde-app/staticPages/redux/reducer';
import { SLICE_NAME as STATIC_PAGES_SLICE_NAME } from 'dde-app/staticPages/redux/slice';

const reducer = combineReducers({
  [CONTEST_SLICE_NAME]: contestReducer,
  [FLIGHTS_SUMMARY_SLICE_NAME]: flightsSummaryReducer,
  [GALLERY_SLICE_NAME]: galleryReducer,
  [NEWSLETTER_SLICE_NAME]: newsletterReducer,
  [OG_TAGS_SLICE_NAME]: ogTagsReducer,
  [PHOTO_CONTEST_SLICE_NAME]: photoContestReducer,
  [STATIC_BLOCKS_SLICE_NAME]: staticBlocksReducer,
  [STATIC_PAGES_SLICE_NAME]: staticPagesReducer,
  i18nState,
  router: connectRouter(history),
});

export {
  reducer,
};
