import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { getLanguage } from 'dde-app/i18n/redux/selectors';
import { NewsletterConnector } from 'dde-app/newsletter/api/NewsletterConnector';
import { subscribe, subscribeFulfilled, subscribeRejected } from './actions';
import { SubscribeAction } from './types';

function* subscribeSaga(action: SubscribeAction): Generator<any, any, any> {
  const { email, subscriptions } = action.payload;
  const language = yield select(getLanguage);

  try {
    yield call(
      requestApiSaga,
      NewsletterConnector.subscribe(email, subscriptions, language),
    );

    yield put(
      subscribeFulfilled(),
    );
  } catch (error) {
    yield put(
      subscribeRejected(
        parseError(error),
      ),
    );
    yield put(
      reportError({
        error,
        message: action.type,
      }),
    );
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(subscribe.type, subscribeSaga),
  ]);
}

export {
  saga,
};
