import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { Winners } from 'dde-app/contest/redux/winners/types';
import { FetchResponseBody, Winner } from './WinnersConnector.types';

class WinnersConnector {
  static fetch(): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/winner?limit=10`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): Winners {
    const {
      data,
    } = response;

    return Array.isArray(data)
      ? data.map((winner: Winner) => ({
        distance: parseInt((winner.distance * 1000).toFixed(0)),
        flightNumber: winner.flightNumber,
        name: winner.name,
        point: [winner.point.lat, winner.point.lon],
        rewardName: winner.rewardName,
      }))
      : [];
  }
}

export {
  WinnersConnector,
};
