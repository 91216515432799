import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import {
  FetchAction,
  FetchFulfilledAction,
  FetchRejectedAction,
  Gallery,
  State,
} from './types';

const SLICE_NAME = 'gallery';

const initialState: State = RemoteRecordStateProvider.getDefault();

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetch: (state, action: FetchAction) => {
      return RemoteRecordStateProvider.getRequested<Gallery>();
    },

    fetchFulfilled: (state, action: FetchFulfilledAction) => {
      const { gallery } = action.payload;

      return RemoteRecordStateProvider.getFulfilled<Gallery>(gallery);
    },

    fetchRejected: (state, action: FetchRejectedAction) => {
      const { error } = action.payload;

      return RemoteRecordStateProvider.getRejected<Gallery>(error);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
