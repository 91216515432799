import { useEffect } from 'react';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { fetch } from 'dde-app/photos/gallery/redux/actions';
import { getGallery } from 'dde-app/photos/gallery/redux/selectors';
import { Gallery } from 'dde-app/photos/gallery/redux/types';

type UseGalleryReturn = RemoteRecord<Gallery>;

const useGallery = (skip?: number, limit?: number): UseGalleryReturn => {
  const dispatch = useAppDispatch();

  const gallery = useAppSelector(
    state => getGallery(state),
  );

  useEffect(
    () => dispatch(fetch({ limit, skip })),
    [
      dispatch,
      limit,
      skip,
    ],
  );

  return gallery;
};

export {
  useGallery,
};
export type {
  UseGalleryReturn,
};
