import React, { ReactElement, useMemo } from 'react';
import { Link } from 'dde-app/common/components/Link';
import { Logo } from 'dde-app/common/components/Logo';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './';
import './NavBar.scss';

const NavBar: Component = ({
  onScrollToClick,
  t,
}: Props): ReactElement => {
  const text = useMemo(
    () => ({
      about: t('About'),
      eventContest: t('Balloon Contest'),
      faq: t('FAQ'),
      photoContest: t('Photo Contest'),
    }),
    [t],
  );

  return (
    <nav className="NavBar">
      <div className="NavBar-Container container">
        <Link className="NavBar-LogoLink" url={'/'}>
          <Logo className="NavBar-Logo" />
        </Link>

        <ul className="NavBar-Nav">
          <li className="NavBar-NavItem">
            <a
              className="NavBar-NavLink"
              href="/"
              onClick={onScrollToClick(ElementId.MAP)}
            >
              {text.eventContest}
            </a>
          </li>

          <li className="NavBar-NavItem">
            <a
              className="NavBar-NavLink"
              href="/"
              onClick={onScrollToClick(ElementId.PHOTO_CONTEST)}
            >
              {text.photoContest}
            </a>
          </li>

          <li className="NavBar-NavItem">
            <a
              className="NavBar-NavLink"
              href="/"
              onClick={onScrollToClick(ElementId.EVENT_INFO)}
            >
              {text.about}
            </a>
          </li>

          <li className="NavBar-NavItem">
            <Link className="NavBar-NavLink" url="/page/faq">
              {text.faq}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const ComposedNavBar = withTranslation(NavBar);

export {
  ComposedNavBar as NavBar,
  NavBar as NavBarComponent,
};
