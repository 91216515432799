import { RootState } from 'dde-app/app/redux/types';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { SLICE_NAME } from './slice';
import { State, StaticPage } from './types';

const getState = (state: RootState): State => state[SLICE_NAME];

const getStaticPage = (
  state: RootState,
  key: string,
): RemoteRecord<StaticPage> => (
  new RemoteRecord(getState(state)[key])
);

export {
  getState,
  getStaticPage,
};
