import { withAnalytics } from 'dde-app/analytics/hoc/withAnalytics';
import { ANALYTICS_PAGE_TYPE, ANALYTICS_STATUS_CODE } from 'dde-app/analytics/utils/analytics';
import { loadData } from './loadData';
import { PhotoContestPage as PhotoContestPageComponent } from './PhotoContestPage';

const PhotoContestPage = withAnalytics(
  PhotoContestPageComponent,
  ANALYTICS_PAGE_TYPE.general,
  () => [],
  () => ({
    server: {
      code: ANALYTICS_STATUS_CODE.loaded,
    },
  }),
);

export {
  loadData,
  PhotoContestPage,
  PhotoContestPageComponent,
};
export * from './types';
