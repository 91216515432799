export default Object.freeze({
  analytics: {
    platform: 'ballon.daydeal.ch',
  },
  basicAuth: {
    enabled: false,
    users: {
      'ddb-team': 'showTheBalloon',
    },
  },
  env: 'prod',
  features: {
    statusSelect: false,
  },
  logger: 'sentry',
  metrics: {
    enabled: true,
  },
  pusher: {
    appKey: '97a5aaaadb6ec039d2fe',
    cluster: 'eu',
  },
  reCaptcha: {
    siteKey: '6LcH4w0bAAAAAMGU88JFsTYZWNDG72OBVlvoCeQr',
  },
  sentry: {
    appId: '5794897',
    publicKey: 'ab1f874a8de645f48ccf176d4455feab',
    sampleRate: 0.25,
  },
  url: {
    api: 'https://data.ballon.daydeal.ch/api',
    map: 'https://maps.ballon.daydeal.ch',
    root: 'https://ballon.daydeal.ch',
    tealium: 'https://i.brack.ch/utag/competec/dealsites/prod/u.js',
  },
});
