import React, { useMemo } from 'react';
import { ProgressBar } from 'dde-app/common/components/ProgressBar';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './types';
import './PhotoContestProgress.scss';

const PhotoContestProgress: Component = ({
  uploadProgress,
  t,
}: Props) => {
  const text = useMemo(
    () => ({
      uploading: t('uploading'),
    }),
    [t],
  );

  return (
    <div className="PhotoContestProgress">
      <div className="PhotoContestProgress-Wrapper">
        <ProgressBar areLabelsDisplayed={false} value={uploadProgress} />
        <span className="PhotoContestProgress-Label">{text.uploading}</span>
      </div>
    </div>
  );
};

const ComposedPhotoContestProgress = withTranslation(PhotoContestProgress);

export {
  ComposedPhotoContestProgress as PhotoContestProgress,
  PhotoContestProgress as PhotoContestProgressComponent,
};
