import React, { MouseEvent, ReactElement, useCallback } from 'react';
import { renderRoutes } from 'react-router-config';
import { scroller } from 'react-scroll';
import { Footer } from 'dde-app/app/components/App/Footer';
import { Header } from 'dde-app/app/components/App/Header';
import { generateUnique } from 'dde-app/common/utils/key/key';
import { isServer } from 'dde-app/common/utils/server';
import { PusherReceiver } from 'dde-app/contest/components/PusherReceiver';
import { usePush } from 'dde-app/routes/hooks/usePush';
import { Component, Props } from './types';
import 'dde-app/common/styles/index.scss';
import './App.scss';

const APP_TITLE = 'DayDeal.ch - Ballon';
const SCROLL_TO_DURATION = 500;

const App: Component = ({ route }: Props): ReactElement => {
  const push = usePush();

  // @ts-ignore
  if (!isServer && window && !window.requestId) {
    // @ts-ignore
    window.requestId = generateUnique();
  }

  const handleScrollToClick = useCallback(
    (elementId: string) => (e?: MouseEvent<HTMLElement>) => {
      e && e.preventDefault();

      push('/');

      setTimeout(
        () => (
          scroller.scrollTo(
            elementId,
            {
              duration: SCROLL_TO_DURATION,
              smooth: 'easeInOutQuart',
            },
          )
        ),
        0,
      );
    },
    [push],
  );

  return (
    <div className="App">
      <Header onScrollToClick={handleScrollToClick} />

      <main>
        {renderRoutes(route.routes)}
      </main>

      <Footer onScrollToClick={handleScrollToClick} />
      {!isServer && <PusherReceiver /> }
    </div>
  );
};

export {
  App,
  APP_TITLE,
};
