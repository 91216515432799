import { AxiosRequestConfig } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';

class PhotoContestConnector {
  static send(
    firstName: string | Blob,
    lastName: string | Blob,
    email: string | Blob,
    photos: string | Blob,
    getUploadProgress: (progressEvent: any) => void,
  ): AxiosRequestConfig {
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('photos', photos);

    return RequestConfig.post(
      `${getConfig('url.api')}/photo`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: getUploadProgress,
      },
    );
  }
}

export {
  PhotoContestConnector,
};
