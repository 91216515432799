import { createFetchFlightsSummaryAction } from 'src/server/actions/createFetchFlightsSummaryAction';
import { createFetchGalleryAction } from 'src/server/actions/createFetchGalleryAction';
import { metrics } from 'src/server/metrics';
import { LoadDataFunctionType } from 'src/server/types';

const APP_MODULE = 'home_page';

const loadData: LoadDataFunctionType = async (handleAction): Promise<any> => {
  metrics.increment(`${APP_MODULE}.request.count`);

  const commonMeta = {
    appModule: APP_MODULE,
  };

  return Promise.all([
    handleAction(
      createFetchFlightsSummaryAction(),
      {
        ...commonMeta,
        action: 'fetchFlightSummary',
        failureMessage: 'Failed to fetch flights summary',
        successMessage: 'Successfully fetched flights summary',

      },
    ),
    handleAction(
      createFetchGalleryAction(),
      {
        ...commonMeta,
        action: 'fetchGallery',
        failureMessage: 'Failed to fetch gallery',
        successMessage: 'Successfully fetched gallery',
      },
    ),
  ]);
};

export {
  loadData,
};
