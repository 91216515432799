// @ts-ignore
import { CALLBACK_ERROR_ARGUMENT, ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { fetch, fetchFulfilled, fetchRejected } from 'dde-app/staticPages/redux/actions';
import { FetchAction, FetchFulfilledAction, FetchRejectedAction } from 'dde-app/staticPages/redux/types';

const createFetchStaticPageAction = (key: string): FetchAction => ({
  ...fetch({
    key,
  }),
  [CALLBACK_ERROR_ARGUMENT]: (action: FetchRejectedAction) => action.payload.error,
  [ERROR_ACTION]: ({ type, payload }: FetchRejectedAction) => type === fetchRejected.type && payload.key === key,
  [WAIT_FOR_ACTION]: ({ type, payload }: FetchFulfilledAction) => type === fetchFulfilled.type && payload.key === key,
});

export {
  createFetchStaticPageAction,
};
