import React  from 'react';
import classNames from 'classnames';
import checkmarkIcon from 'dde-app/common/assets/icons/icon-checkmark.svg';
import { Error } from 'dde-app/common/components/Form/common/Error';
import { Component, Props } from './types';
import './Checkbox.scss';

const Checkbox: Component = ({
  children,
  className,
  defaultChecked,
  error,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  register,
  value,
}: Props) => {
  const rootClassName = classNames(
    'Checkbox',
    className,
  );

  return (
    <>
      <label className={rootClassName}>
        <input
          {...(register ? register(name, { required: isRequired }) : {})}
          className="Checkbox-Input"
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          type="checkbox"
          value={value}
        />

        <div className="Checkbox-Field">
          <img
            alt="Checkbox mark"
            className="Checkbox-Icon"
            src={checkmarkIcon}
          />
        </div>
        {children}
        {(label || isRequired) && (
          <span className="Checkbox-Label">
            {label || ''}

            {isRequired && (
              <span className="Checkbox-LabelRequired">*</span>
            )}
          </span>
        )}
      </label>

      {error && <Error>{error}</Error>}
    </>
  );
};

export {
  Checkbox,
};
