import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { FlightsSummary } from 'dde-app/home/redux/types';
import { FetchResponseBody } from './FlightsSummaryConnector.types';

class FlightsSummaryConnector {
  static fetch(): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/flight/summary`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): FlightsSummary {
    const { data } = response;

    return {
      totalDistance: data.totalDistance,
      totalFlights: data.totalFlights,
      totalTimeInHours: data.totalTimeInHours,
    };
  }
}

export {
  FlightsSummaryConnector,
};
