import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { StaticBlock } from 'dde-app/staticBlocks/redux/types';
import { FetchResponseBody } from './StaticBlockConnector.types';

class StaticBlockConnector {
  static fetch(key: string): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/static-block/${key}`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): StaticBlock {
    const { data } = response;

    return {
      content: data.content,
    };
  }
}

export {
  StaticBlockConnector,
};
