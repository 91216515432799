import axios from 'axios';
import { call } from 'redux-saga/effects';

function* requestApiSaga(requestConfig: any): Generator<any, any, any> {
  const { ...config } = requestConfig;

  return yield call(
    axios.request,
    config,
  );
}

export {
  requestApiSaga,
};
