import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from 'connected-react-router';
import I18n from 'redux-i18n';
import { appSaga } from 'dde-app/app/redux/saga';
import { createStore } from 'dde-app/app/redux/store';
import { history } from 'dde-app/common/utils/history';
import { setLogger } from 'dde-app/common/utils/logger/logger';
import { consoleLogger } from 'dde-app/common/utils/logger/logger/console';
import { sentryLogger } from 'dde-app/common/utils/logger/logger/sentry';
import { getConfig } from 'dde-app/config/utils/config';
import { getLanguage } from 'dde-app/i18n/redux/selectors';
import { translations } from 'dde-app/i18n/translations/translations';
import { ROUTE_CONFIG } from 'dde-app/routes/components/Router';
import { version } from '../../package.json';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

enum NewVersionInfo {
  DE = 'Neue Version der App ist verfügbar! Klicken Sie zum Aktualisieren auf OK.',
  EN = 'New version of app is available! Click OK to refresh.',
}

const store = createStore();

store.runSaga(appSaga);

const state = store.getState();
const language = getLanguage(state);

const logger = getConfig('logger');
const sentryKey = getConfig('sentry.publicKey');
const sentryAppId = getConfig('sentry.appId');
const sentrySampleRate = getConfig('sentry.sampleRate', 1);

switch (logger) {
  case 'sentry':
    Sentry.init({
      dsn: `https://${sentryKey}@sentry.io/${sentryAppId}`,
      release: `v${version}`,
      tracesSampleRate: sentrySampleRate,
    });

    setLogger(sentryLogger);
    break;

  case 'console':
    setLogger(consoleLogger);
    break;

  default:
    break;
}

const rootElement = document.getElementById('root');

ReactDOM.hydrate(
  // @ts-ignore
  <Provider store={store}>
    <I18n translations={translations} initialLang="de" fallbackLang="en">
      <ConnectedRouter history={history}>
        {renderRoutes(ROUTE_CONFIG)}
      </ConnectedRouter>
    </I18n>
  </Provider>,
  rootElement,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (confirm(NewVersionInfo[language.toUpperCase()] || NewVersionInfo.EN)) {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }

      window.location.reload(true);
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
