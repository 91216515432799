import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useClearSubmitPhotoContest } from 'src/client/photos/photoContest/hooks/useClearSubmitPhotoContest';
import { useGetSubmitPhotoContestData } from 'src/client/photos/photoContest/hooks/useGetSubmitPhotoContestData';
import { useSubmitPhotoContest } from 'src/client/photos/photoContest/hooks/useSubmitPhotoContest';
import closeBlackImg from 'dde-app/common/assets/close-black.png';
import { Button } from 'dde-app/common/components/Button';
import { Checkbox } from 'dde-app/common/components/Form/Checkbox';
import { TextInput } from 'dde-app/common/components/Form/TextInput';
import { parseValidationErrors } from 'dde-app/common/utils/api/error';
import {
  EMAIL_PATTERN,
  emailRule,
  inputRule,
} from 'dde-app/common/utils/validations/validationRules';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { PhotoContestConfirmation } from './PhotoContestConfirmation';
import { PhotoContestPreview } from './PhotoContestPreview/PhotoContestPreview';
import { PhotoContestProgress } from './PhotoContestProgress/PhotoContestProgress';
import { Component, Props } from './types';
import './PhotoContestModal.scss';

const MAX_PHOTO_SIZE = 15 * 1000000;

enum FormField {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHOTO = 'photo',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  _ERROR = '_error',
}

const PhotoContestModal: Component = ({
  isModalOpen,
  onModalToggle,
  t,
}: Props) => {
  const text = useMemo(
    () => ({
      browse: t('browse'),
      choosePhoto: t('choose photo'),
      close: t('close'),
      content1: t('Snapped a photo of DayDeal hot-air balloon?'),
      content2: t('Each accepted photo we publish in gallery wins a'),
      contentBold: t('DayDeal voucher code!'),
      dragPhoto: t('drag a photo or'),
      emailPlaceholder: t('your email'),
      errorDefault: t('An error occured'),
      errorEmailEmpty: t('Email should not be empty'),
      errorEmailPattern: t('Entered value does not match email format'),
      fieldRequired: t('Field is required'),
      fileSizeType: t('JPEG or PNG up to 15 MB'),
      fileTooLarge: t('file too large'),
      firstNamePlaceholder: t('first name'),
      iReadAndUnderstand: t('I read and understand'),
      info: t('Each submitted photo will be validated by DayDeal team for conformity with terms and conditions. Accepted entries will receive a free DayDeal voucher code which will be send to e-mail address provided below.'),
      invalidEmail: t('Invalid email'),
      invalidFileType: t('file too large'),
      invalidImage: t('Invalid image file'),
      lastNamePlaceholder: t('last name'),
      modalSelect: t('Please select image for upload'),
      notEmpty: t('Field is empty'),
      photoContest: t('Photo Contest'),
      photos: t('Photos'),
      required: t('Required to send you a voucher. We do not disclose your last name to the public.'),
      stringRequired: t('string required'),
      submitPhoto: t('submit photo'),
      termsAndConditions: t('terms and conditions'),
      tooManyFiles: t('too many files'),
      unknownFileError: t('error: unknown file error, please try again'),
      viewGallery: t('view gallery'),
    }),
    [t],
  );

  const submitPhotoContest = useSubmitPhotoContest();
  const photoContestData = useGetSubmitPhotoContestData();
  const clearSubmitPhotoContest = useClearSubmitPhotoContest();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [preloadedImage, setPreloadedImage] = useState(null);
  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
  } = useForm();

  const onDrop = useCallback((acceptedFiles: any) => {
    setPreloadedImage(acceptedFiles[0]);
    clearErrors(FormField._ERROR);
  }, [clearErrors]);

  const handleRemovePreviewImage = useCallback(() => {
    setPreloadedImage(null);
    clearErrors(FormField._ERROR);
  }, [clearErrors]);

  const handleManuallySetError = useCallback(
    (fieldName: string, message?: string): void => {
      // @ts-ignore
      setError(fieldName, { message, type: 'manual' });
    },
    [setError],
  );

  useEffect(
    () => {
      if (photoContestData.isRejected) {
        const { validationErrors } = photoContestData.state.error;

        if (!validationErrors) {
          return handleManuallySetError(FormField._ERROR, text.errorDefault);
        }

        const parsedErrors = parseValidationErrors(validationErrors);

        const areValidationErrors = Object
          .keys(parsedErrors)
          .reduce(
            (globalError: boolean, fieldName: string): boolean => {
              const fieldErrorKey = parsedErrors[fieldName];

              // @ts-ignore
              if (getValues(fieldName) && fieldErrorKey) {
                handleManuallySetError(fieldName, text[fieldErrorKey]);

                return true;
              }

              return globalError;
            },
            false,
          );

        if (!areValidationErrors) {
          return handleManuallySetError(FormField._ERROR);
        }
      }
    },
    [
      getValues,
      handleManuallySetError,
      photoContestData,
      setError,
      text,
    ],
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    maxFiles: 1,
    maxSize: MAX_PHOTO_SIZE,
    multiple: false,
    noKeyboard: true,
    onDrop,
  });

  const isDropzoneRejected = Array.isArray(fileRejections) && !!fileRejections.length;

  const getUploadProgress = useCallback((progressEvent: any): void => {
    const { loaded, total } = progressEvent;
    const progressPercentage = ((loaded / total) * 100).toFixed();

    setUploadProgress(progressPercentage);
  }, [setUploadProgress]);

  const onFormSubmit: SubmitHandler<any> = useCallback((
    {
      email,
      firstName,
      lastName,
    },
  ) => {
    if (preloadedImage) {
      submitPhotoContest(
        {
          email,
          firstName,
          getUploadProgress,
          lastName,
          photos: preloadedImage,
        },
      );
    }
  }, [
    getUploadProgress,
    preloadedImage,
    submitPhotoContest,
  ]);

  const getDropzoneError = useCallback((errorCode) => {
    switch (errorCode) {
      case 'file-invalid-type':
        return text.invalidFileType;

      case 'too-many-files':
        return text.tooManyFiles;

      case 'file-too-large':
        return text.fileTooLarge;

      default:
        return text.unknownFileError;
    }
  }, [text]);

  const handleModalClose = useCallback((): void => {
    onModalToggle(false);
    clearSubmitPhotoContest();
    setPreloadedImage(null);
  }, [
    clearSubmitPhotoContest,
    onModalToggle,
    setPreloadedImage,
  ]);


  return (
    <Modal
      className="PhotoContestModal-Box"
      overlayClassName="PhotoContestModal-Overlay"
      isOpen={isModalOpen}
      onRequestClose={handleModalClose}
    >
      <img className="PhotoContestModal-CloseBtn" src={closeBlackImg} onClick={handleModalClose} />
      {photoContestData.isFulfilled && <PhotoContestConfirmation onModalClose={handleModalClose} />}

      {!photoContestData.isFulfilled && (
        <div className="PhotoContestModal-Content container">
          <div>
            <h2 className="PhotoContestModal-Title text-center">{text.submitPhoto}</h2>
            <p className="PhotoContestModal-Text text-center">{text.content1}</p>
            <p className="PhotoContestModal-Text text-center">{text.content2}<span className="PhotoContestModal-ContentBold"> {text.contentBold}</span></p>
            <form
              className="PhotoContestModal-Form row"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
                  {photoContestData.isRequested && <PhotoContestProgress uploadProgress={uploadProgress} />}
                  {(!photoContestData.isRequested && preloadedImage) && (
                    <PhotoContestPreview onRemovePreviewImage={handleRemovePreviewImage} preloadedImage={preloadedImage} />
                  )}
                  {(!photoContestData.isRequested && !preloadedImage) && (
                    <>
                      <div className={`PhotoContestModal-DropzoneBox text-center ${isDropzoneRejected && 'PhotoContestModal-Rejected'}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="PhotoContestModal-DragText">
                          {text.dragPhoto}
                          <span className="PhotoContestModal-BrowseButton">{text.browse}</span>
                        </p>
                        <Button
                          className="PhotoContestModal-FileButton"
                          isFullWidth={false}
                          isFormSubmitPrevented={true}
                          size={Button.Size.DEFAULT}
                        >
                          {text.choosePhoto}
                        </Button>
                        <p className="PhotoContestModal-FileText">
                          {text.fileSizeType}
                        </p>
                      </div>
                    </>
                  )}
                  {!(isDropzoneRejected || photoContestData.isRejected) && (
                    <ul className="PhotoContestModal-FileErrorList">
                      {fileRejections.map(({ errors }) => (errors
                        .map(error => (
                          <li className="PhotoContestModal-FileError" key={error.code}>{getDropzoneError(error.code)}</li>
                        ))
                      ))}
                      {errors?.[FormField._ERROR] && (
                        <li className="PhotoContestModal-FileError">{errors[FormField._ERROR].message}</li>
                      )}
                    </ul>
                  )}
              </div>
              <div className="col-12 col-md-6 col-lg-4 ">
                <TextInput
                  className="PhotoContestModal-TextInput"
                  defaultValue={''}
                  error={emailRule(errors[FormField.EMAIL], text)}
                  isRequired={true}
                  name={FormField.EMAIL}
                  placeholder={text.emailPlaceholder}
                  register={register}
                  type={TextInput.Type.EMAIL}
                  validationPattern={EMAIL_PATTERN}
                />
                <TextInput
                  className="PhotoContestModal-TextInput"
                  defaultValue={''}
                  error={inputRule(errors[FormField.FIRST_NAME], text)}
                  isRequired={true}
                  name={FormField.FIRST_NAME}
                  placeholder={text.firstNamePlaceholder}
                  register={register}
                  type={TextInput.Type.TEXT}
                />
                <TextInput
                  className="PhotoContestModal-TextInput"
                  defaultValue={''}
                  error={inputRule(errors[FormField.LAST_NAME], text)}
                  isRequired={true}
                  name={FormField.LAST_NAME}
                  placeholder={text.lastNamePlaceholder}
                  register={register}
                  type={TextInput.Type.TEXT}
                />
                <p className="PhotoContestModal-Info">
                  {text.required}
                </p>
                <Checkbox
                  className="PhotoContestModal-TermsCheckbox"
                  defaultChecked={false}
                  error={inputRule(errors[FormField.TERMS_AND_CONDITIONS], text)}
                  isRequired={true}
                  name={FormField.TERMS_AND_CONDITIONS}
                  register={register}
                >
                  {text.iReadAndUnderstand}
                  <a
                    target='_blank'
                    className="PhotoContestModal-TermsText"
                    href={'/page/teilnahmebedingungen'}
                    rel="noreferrer"
                  >
                    {text.termsAndConditions}
                  </a>
                </Checkbox>
                <p className="PhotoContestModal-Info">
                  {text.info}
                </p>
                <Button
                  className="PhotoContestModal-FormButton"
                  size={Button.Size.DEFAULT}
                  isDisabled={!preloadedImage || photoContestData.isRequested}
                  //@ts-ignore
                  type="submit"
                >
                  {text.submitPhoto}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Modal>
  );
};

const ComposedPhotoContestModal = withTranslation(PhotoContestModal);

export {
  ComposedPhotoContestModal as PhotoContestModal,
  PhotoContestModal as PhotoContestModalComponent,
};
