import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useOgTags } from 'dde-app/ogTags/hooks/useOgTags';
import { Component, Props } from './';

const Head: Component = ({
  title,
}: Props): ReactElement => {
  const ogTags = useOgTags();

  if (!ogTags.isFulfilled) {
    return null;
  }

  const { data } = ogTags;

  return (
    <Helmet>
      <title>{title}</title>

      {Array.isArray(data) && data.map(({ property, content }, index) => (
        <meta
          content={content}
          key={index}
          property={property}
        />
      ))}
    </Helmet>
  );
};

export {
  Head,
};
