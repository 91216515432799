import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { Gallery } from 'dde-app/photos/gallery/redux/types';
import { FetchResponseBody } from './GalleryConnector.types';

class GalleryConnector {
  static fetch(
    skip: number,
    limit: number,
  ): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/photo?skip=${skip}&limit=${limit}`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): Gallery {
    const { data } = response;

    return Array.isArray(data)
      ? data.map(photo => {
        return {
          filePath: photo.filePath,
          filePathLarge: photo.filePathLarge,
          filePathThumbnail: photo.filePathThumbnail,
          userName: photo.userName,
          uuid: photo.uuid,
        };
      })
      : [];
  }
}

export {
  GalleryConnector,
};
