import { createFetchGalleryAction } from 'src/server/actions/createFetchGalleryAction';
import { metrics } from 'src/server/metrics';
import { LoadDataFunctionType } from 'src/server/types';

const APP_MODULE = 'photo_contest';

const loadData: LoadDataFunctionType = async (handleAction): Promise<any> => {
  metrics.increment(`${APP_MODULE}.request.count`);

  return handleAction(
    createFetchGalleryAction(),
    {
      action: 'fetchGallery',
      appModule: APP_MODULE,
      failureMessage: 'Failed to fetch gallery',
      successMessage: 'Successfully fetched gallery',
    },
  );
};

export {
  loadData,
};
