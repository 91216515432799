import { RootState } from 'dde-app/app/redux/types';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { SLICE_NAME } from './slice';
import { State } from './types';

const getState = (state: RootState): State => state[SLICE_NAME];

const getNewsletter = (state: RootState): RemoteRecord<any> => (
  new RemoteRecord(getState(state))
);

export {
  getNewsletter,
  getState,
};
