import { RootState } from 'dde-app/app/redux/types';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { SLICE_NAME } from './slice';
import { Gallery, State } from './types';

const getState = (state: RootState): State => state[SLICE_NAME];

const getGallery = (
  state: RootState,
): RemoteRecord<Gallery> => (
  new RemoteRecord(getState(state))
);

export {
  getGallery,
  getState,
};
