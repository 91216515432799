import React, { FC, ReactElement } from 'react';
import { APP_TITLE } from 'dde-app/app/components/App/App';
import { Head } from 'dde-app/app/components/App/Head';
import { Props } from './';
import './NotFound.scss';

const NotFoundPage: FC<Props> = (props: Props): ReactElement => {
  const { staticContext = {} } = props;
  staticContext.notFound = true;

  return (
    <>
      <Head title={APP_TITLE} />

      <div className="NotFound container">
        <h1>Ooops, route not found.</h1>
      </div>
    </>
  );
};

export {
  NotFoundPage,
};
