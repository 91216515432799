import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { getNewsletter } from 'dde-app/newsletter/redux/selectors';

type UseNewsletterReturn = RemoteRecord<any> | null;

const useNewsletter = (): UseNewsletterReturn => useAppSelector(getNewsletter);

export {
  useNewsletter,
};
