import { RootState } from 'dde-app/app/redux/types';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { SLICE_NAME as PARENT_SLICE_NAME } from 'dde-app/contest/redux/reducer';
import { SLICE_NAME } from './slice';
import { FlightData, State } from './types';

const getState = (state: RootState): State => state[PARENT_SLICE_NAME][SLICE_NAME];
const getFlightData = (state: RootState): RemoteRecord<FlightData | null> => (
  new RemoteRecord(getState(state))
);

export {
  getFlightData,
  getState,
};
