import React, { FC, ReactElement, useMemo } from 'react';
import { Head } from 'dde-app/app/components/App/Head';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Gallery } from 'dde-app/photos/gallery/components/Gallery';
import { Props } from '.';
import './PhotoContestPage.scss';

const PhotoContestPage: FC<Props> = ({
  t,
}: Props): ReactElement => {
  const text = useMemo(
    () => ({
      pageTitle: t('Ballon Photo Contest'),
    }),
    [t],
  );

  return (
    <>
      <Head title={text.pageTitle}  />

      <div className="PhotoContestPage container">
        <h1 className="PhotoContestPage-Title">{text.pageTitle}</h1>
        <Gallery isHomePage={false} />
      </div>
    </>
  );
};

const ComposedPhotoContestPage = withTranslation(PhotoContestPage);

export {
  ComposedPhotoContestPage as PhotoContestPage,
  PhotoContestPage as PhotoContestPageComponent,
};
