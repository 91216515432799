export const translations = {
  de: {
    '3/4 pax.': '1/2 Personen',
    '16,9 m': '16,9 m',
    '17 M': '17 m',
    '2200 m3': '2200 m³',
    About: 'Ballon',
    'After flight is finished person who pointed closest location of landing will win main prize.': 'Der Tipp, der am nächsten beim tatsächlichen Landeort liegt, gewinnt!',
    'An Error occured.': 'Es ist ein Fehler aufgetreten.',
    'An error occured': 'Es ist ein Fehler aufgetreten',
    'Back to current flight': 'Zurück zur Ballonfahrt',
    'Ballon Photo Contest': 'Foto-Galerie',
    'Ballon contest': 'Wettbewerb',
    'Balloon Contest': 'Wettbewerb',
    'Balloon flight will take approximately 90 minutes. The challenge is to guess the location of balloon landing. ': 'Sobald der Wettbewerb startet, siehst du die Position des Ballons auf der Karte und kannst seinen Landeort schätzen.',
    'Balloon is landing. Please wait': 'Das Voting ist geschlossen. Warte bis zur Landung',
    Capacity: 'Kapazität',
    City: 'Ort',
    'Contest is live! Make a guess and win a reward!': 'Wir sind live! Jetzt schätzen und gewinnen!',
    'Curious about our new toy?': 'Willst du mehr über den grünen Fleck am Himmel erfahren?',
    'Current altitude:': 'Aktuelle Höhe:',
    'DON’T miss next contest!': 'Keinen Ballonstart verpassen!',
    'DayDeal voucher code!': ' DayDeal-Gutschein!',
    Diameter: 'Durchmesser',
    'Disable notifications': 'Deaktivieren',
    Distance: 'Distanz',
    'Distance covered:': 'Gefahrene Strecke:',
    'E-MAIL ADDRESS': 'E-Mail-Adresse',
    'Each accepted photo we publish in gallery wins a': 'Lade es jetzt hoch und erhalte einen',
    'Each submitted photo will be validated by DayDeal team for conformity with terms and conditions. Accepted entries will receive a free DayDeal voucher code which will be send to e-mail address provided below.': 'Nach dem Hochladen überprüfen wir dein Foto gemäss unseren Richtlinien: Der DayDeal-Ballon muss deutlich zu erkennen sein die Mindestmasse von 1000 x 1000 Pixeln sind erfüllt.',
    'Each value in subscriptions must be a valid enum value': '',
    'Email should not be empty': 'Bitte gib eine E-Mail-Adresse ein',
    'Enable instant in-browser notification whenever new contest starts.': 'Erhalte sofort eine Browser-Benachrichtigung, sobald der Ballon in die Luft geht!',
    'Enable notifications': 'Aktivieren',
    'Enter Contest': 'Jetzt teilnehmen',
    'Entered value does not match email format': 'Bitte gib eine gültige E-Mail-Adresse an',
    'Entered value does not match postal code format': '',
    FAQ: 'FAQ',
    'Field is empty': 'Feld ist leer',
    'Field is required': 'Pflichtfeld',
    Finish: 'Landung',
    'First name': 'Vorname',
    Flight: 'Fahrt',
    'Flight duration:': 'Dauer der Fahrt:',
    'Flight no.': 'Fahrt-Nr.',
    'Flight progress (approximate)': 'Fortschritt (ungefähr)',
    'Flights are handled by': 'Gefahren von',
    Geschenkkarten: 'Geschenkkarten',
    'Get newsletter': 'Jetzt anmelden',
    'Get notified about THE upcoming flight': 'Benachrichtigung zum Ballonstart',
    'Guess the landing position of the balloon!': 'Landeposition schätzen und gewinnen!',
    'Guess the location of landing': 'Schätze den Landeort des Ballons',
    Height: 'Höhe',
    'Here’s a few facts.': 'Hier findest du ein paar spannende Fakten.',
    'How to enter:': 'So machst du mit:',
    'I read and understand': 'Ich akzeptiere die',
    Impressum: 'Impressum',
    'In flight': 'In der Luft',
    'Invalid email': 'Ungültige E-Mail-Adresse',
    'Invalid image file': '',
    'Invalid latitude': '',
    'Invalid longitude': '',
    'JPEG or PNG up to 15 MB': 'JPEG oder PNG bis 15 MB',
    Landed: 'Gelandet',
    'Lang must be a valid enum value': '',
    'Last name': 'Nachname',
    'Make a guess balloon will end its current flight': 'Schätze den Landeort des Heissluftballons',
    'Mark your guess on the map': 'Klicke auf die Karte, um einen Tipp abzugeben',
    Neuigkeiten: 'Neuigkeiten',
    'Next contest coming soon — Don’t miss!': 'Verpasse keinen Ballonstart!',
    'Next flight coming soon.': 'Die nächste Fahrt kommt bald.',
    'Now only wait for winner announcement!': 'Der Gewinner wird Kurz nach der Landung bekanntgegeben.',
    Partner: 'Partner',
    'Past winners': 'Letzte Gewinner',
    'Photo Contest': 'Fotos',
    'Photo Gallery': 'Fotos',
    'Photo contest': 'Fotos',
    'Photo of DayDeal Balloon': 'Foto des DayDeal Ballons',
    'PhotoContest Page Description': 'Lade ein Foto hoch und erhalte eine Belohnung!',
    Photos: 'Fotos',
    'Play now': 'Mitmachen',
    'Please select image for upload': 'Bitte wähle ein Bild aus',
    Pointed: 'tippte',
    'Postal code': 'PLZ',
    'Preparing for take off': 'Startvorgang',
    'Preparing to land': 'Landevorgang',
    'Provide your contact information. We use your data to send a prize and do not share it with third parties.': 'Deine Kontaktdaten werden ausschliesslich dazu verwendet, dass wir dir im Fall eines Gewinnes den Preis zustellen können.',
    'Receive DayDeal news and offers': 'DayDeal News: Täglich den neusten Deal in deinem Postfach',
    'Receive e-mail whenever new contest starts.': 'Erhalte ein E-Mail direkt in dein Postfach, sobald der Ballon startet!',
    'Required to send you a voucher. We do not disclose your last name to the public.': 'Bitte beachte, dass deine Belohnung an die angegebene E-Mail gesendet wird. Dein Nachname wird nicht veröffentlicht.',
    Reward: 'Gewinn',
    'Reward photo': 'Wettbewerbspreis',
    SUBSCRIBE: 'Abonnieren',
    Satellite: 'Satellit',
    'Snap a photo and upload it to our gallery. Each accepted photo wins a ': 'Schiesse ein Foto und lade es in unsere Galerie hoch! Für jedes gültige Foto verschenken wir einen',
    'Snapped a photo of DayDeal hot-air balloon?': 'Hast du ein Foto des DayDeal-Ballons gemacht?',
    'Something went wrong': 'Ups, da ist etwas schief gelaufen',
    'Sorry, no winner this time': 'Sorry, dieses Mal hat leider niemand gewonnen',
    'Spotted DayDeal Balloon?': 'HAST DU DEN DAYDEAL-BALLON GESEHEN?',
    Start: 'Start',
    'Start time:': 'Startzeit:',
    'Stay tuned.': 'Bleib bei uns!',
    'Street and number': 'Strasse und Nummer',
    Streets: 'Karte',
    Submit: 'Absenden',
    'Subscribe to DayDeal Balloon newsletter to receive notifications on upcoming events': 'Ballon News: Damit du die nächste Fahrt nicht verpasst',
    'Subscribe to our Balloon newsletter or turn on notifications in browser to get informed when flight start.': 'Lass dich bei jedem Ballonstart benachrichtigen, damit du den nächsten Wettbewerb nicht verpasst.',
    'Subscriptions must be an array': '',
    'Thank you for voting': 'Vielen Dank für deinen Tipp',
    'Thank you for your submission': 'Vielen Dank für deinen Upload!',
    'The Balloon': 'Der DayDeal-Ballon',
    'This email has been already used for voting in the current contest': 'Mit dieser E-Mail wurde im aktuellen Wettbewerb bereits teilgenommen',
    'Today’s reward': 'Das kannst du gewinnen',
    'Total distance travelled': 'Total zurückgelegte Strecke',
    'Total flight time': 'Gesamte Fahrtzeit',
    'Total flights': 'Anzahl Fahrten',
    Unternehmen: 'Unternehmen',
    'Value must be string': '',
    Volume: 'Volumen',
    'Wait for announcing a winner!': 'Warte, bis der Ballon gelandet ist',
    'Waiting for winner announcement': 'Gewinner wird gleich bekannt gegeben',
    'We couldn\'t submit your entry. Please try again later': 'Da hat leider etwas nicht geklappt. Bitte probiere später nochmals. ',
    'We’re experiencing technical issues and won’t be able to continue with the contest today. No winner will be announced.': 'Aufgrund technischer Probleme muss die aktuelle Ballonfahrt leider ohne Gewinner beendet werden. Besten Dank für dein Verständnis. ',
    Winner: 'Gewinner(in)',
    'You already voted': 'Du hast bereits gevotet',
    'Your current selection': 'Deine geschätzte Landeposition',
    'Your e-mail subscription is now active!': 'Vielen Dank für deine Anmeldung! \nBitte prüfe dein Postfach, um deine E-Mail zu bestätigen.',
    'Your guess': 'Deine geschätzte Landeposition',
    'Your photo awaits verification by our team.': 'Dein Foto wird bald von unserem Team geprüft.',
    away: 'entfernt',
    back: 'zurück',
    browse: 'durchsuchen',
    'choose photo': 'Foto wählen',
    close: 'Schliessen',
    closed: 'ist fertig',
    continue: 'weiter',
    'drag a photo or': 'Foto hierher ziehen oder',
    'e-mail': 'E-Mail',
    'error: unknown file error, please try again': 'Datei nicht erkannt. Bitte nochmals versuchen.',
    'file too large': '',
    finish: 'Ziel',
    'first name': 'Vorname',
    flight: 'Fahrt',
    'flight contest': 'Wettbewerb',
    hours: 'Stunden',
    is: 'ist',
    km: 'km',
    'last name': 'Nachname',
    'live now': 'Jetzt live',
    'm.a.s.l.': 'm ü.M.',
    'skywalker-ballon.ch': 'skywalker-ballon.ch',
    'skywalker-balloon.ch': 'skywalker-ballon.ch',
    start: 'Start',
    step: 'Schritt',
    'string required': '',
    'submit photo': 'Foto hochladen',
    'terms and conditions': 'Teilnahmebedingungen',
    'too many files': '',
    uploading: 'hochladen',
    'view full gallery': 'Galerie öffnen',
    'view gallery': 'Galerie öffnen',
    'winner:': 'Gewinner:',
    'your email': 'E-Mail',
    'Über ballon': 'Über den Ballon',
    'Über daydeal': 'Über DayDeal',
  },
  options: {
    plural_number: '2',
    plural_rule: 'n != 1',
  },
};
