import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestConfig } from 'dde-app/common/utils/api/RequestConfig';
import { getConfig } from 'dde-app/config/utils/config';
import { StaticPage } from 'dde-app/staticPages/redux/types';
import { FetchResponseBody } from './StaticPageConnector.types';

class StaticPageConnector {
  static fetch(key: string): AxiosRequestConfig {
    return RequestConfig.get(
      `${getConfig('url.api')}/static-page/${key}`,
    );
  }

  static mapResponseToState(
    response: AxiosResponse<FetchResponseBody>,
  ): StaticPage {
    const { data } = response;

    return {
      content: data.content,
      key: data.key,
      title: data.title,
    };
  }
}

export {
  StaticPageConnector,
};
