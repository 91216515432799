import { RootState } from 'dde-app/app/redux/types';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { SLICE_NAME } from './slice';
import { FlightsSummary, State } from './types';

const getState = (state: RootState): State => state[SLICE_NAME];
const getFlightsSummary = (state: RootState): RemoteRecord<FlightsSummary | null> => (
  new RemoteRecord(getState(state))
);

export {
  getFlightsSummary,
  getState,
};
