import React, { useMemo } from 'react';
import greenTickImg from 'dde-app/common/assets/green-tick.png';
import { Button } from 'dde-app/common/components/Button';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './types';
import './PhotoContestConfirmation.scss';

const PhotoContestConfirmation: Component = ({
  onModalClose,
  t,
}: Props) => {
  const text = useMemo(
    () => ({
      close: t('close'),
      successInfo: t('Your photo awaits verification by our team.'),
      successTitle: t('Thank you for your submission'),
    }),
    [t],
  );


  return (
    <div className="PhotoContestConfirmation-Content container text-center">
      <div>
        <img className="PhotoContestConfirmation-Icon" src={greenTickImg} alt="ok"/>
        <h2 className="PhotoContestConfirmation-Title text-center">{text.successTitle}</h2>
        <p className="PhotoContestConfirmation-Text text-center">{text.successInfo}</p>
        <Button className="PhotoContestConfirmation-CloseButton" styleType={Button.StyleType.LIGHT} onClick={onModalClose} >{text.close}</Button>
      </div>
    </div>
  );
};

const ComposedPhotoContestConfirmation = withTranslation(PhotoContestConfirmation);

export {
  ComposedPhotoContestConfirmation as PhotoContestConfirmation,
  PhotoContestConfirmation as PhotoContestConfirmationComponent,
};
