import { createLogger, format, transports } from 'winston';
import { getConfig } from 'dde-app/config/utils/config';

const logger = createLogger({
  defaultMeta: {
    app_version: getConfig('version'),
    service: getConfig('appName'),
  },
  transports: [
    new transports.Console({
      format: format.combine(
        format.timestamp(),
        format.json(),
      ),
    }),
  ],
});

export {
  logger,
};
