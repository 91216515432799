import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import { FetchFulfilledAction, FetchRejectedAction, State, Winners } from './types';

const SLICE_NAME = 'winners';

const initialState: State = null;

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    fetch: () => {
      return RemoteRecordStateProvider.getRequested<Winners>();
    },

    fetchFulfilled: (state, action: FetchFulfilledAction) => {
      return RemoteRecordStateProvider.getFulfilled<Winners>(action.payload);
    },

    fetchRejected: (state, action: FetchRejectedAction) => {
      return RemoteRecordStateProvider.getRejected<Winners>(action.payload);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
