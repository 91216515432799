import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import { State, VoteAction, VoteFulfilledAction, VoteRejectedAction } from './types';

const SLICE_NAME = 'vote';

const initialState: State = null;

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    vote: (state, action: VoteAction) => {
      return RemoteRecordStateProvider.getRequested();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    voteFulfilled: (state, action: VoteFulfilledAction) => {
      return RemoteRecordStateProvider.getFulfilled();
    },
    voteRejected: (state, action: VoteRejectedAction) => {
      return RemoteRecordStateProvider.getRejected(action.payload);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
