import { all, select, takeEvery } from 'redux-saga/effects';
import { handleError } from 'dde-app/common/utils/api/error';
import { getLanguage } from 'dde-app/i18n/redux/selectors';
import { reportError } from './actions';
import { ReportErrorAction } from './types';

function* reportErrorSaga(action: ReportErrorAction): Generator<any, any, any> {
  const {
    additionalData,
    error,
    ignoreNetwork,
    message,
  } = action.payload;
  const language = yield select(getLanguage);

  handleError(
    message,
    {
      additionalData,
      error,
      language,
      message,
    },
    ignoreNetwork,
  );
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(reportError.type, reportErrorSaga),
  ]);
}

export {
  saga,
};
