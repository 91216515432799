import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getCookie } from 'react-use-cookie';
import classNames from 'classnames';
import checkImg from 'dde-app/common/assets/check.png';
import bellIconImg from 'dde-app/common/assets/icons/icon-bell.svg';
import newsletterIconImg from 'dde-app/common/assets/icons/icon-newsletter.svg';
import { Button } from 'dde-app/common/components/Button';
import { TextInput } from 'dde-app/common/components/Form/TextInput';
import { isServer } from 'dde-app/common/utils/server';
import { EMAIL_PATTERN } from 'dde-app/common/utils/validations/validationRules';
import { emailRule } from 'dde-app/common/utils/validations/validationRules';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { useNewsletter } from 'dde-app/newsletter/hooks/useNewsletter';
import { useSubscribe } from 'dde-app/newsletter/hooks/useSubscribe';
import { Component, Props } from './types';
import './Subscriptions.scss';

enum FormField {
  EMAIL = 'email',
}

const Subscriptions: Component = ({
  className,
  handleNotificationsClick,
  isEmbedded = false,
  isSubscribedToNotifications,
  isIOS,
  subscriptionsActivated = ['DDB'],
  t,
}: Props) => {
  const rootClassName = classNames(
    'Subscriptions',
    {
      'Subscriptions--MapEmbedded': isEmbedded,
    },
    className,
  );

  const text = useMemo(
    () => ({
      disableNotificationsButton: t('Disable notifications'),
      emailPlaceholder: t('E-MAIL ADDRESS'),
      embeddedTitle: t('Next contest coming soon — Don’t miss!'),
      enableNotificationsButton: t('Enable notifications'),
      errorDefault: t('An error occured'),
      errorEmailEmpty: t('Email should not be empty'),
      errorEmailPattern: t('Entered value does not match email format'),
      errorLangValue: t('Lang must be a valid enum value'),
      errorSubscriptions: t('Each value in subscriptions must be a valid enum value'),
      errorSubscriptionsArray: t('Subscriptions must be an array'),
      fieldRequired: t('Field is required'),
      getNewsletterButton: t('Get newsletter'),
      newsletterContent: t('Receive e-mail whenever new contest starts.'),
      newsletterError: t('An Error occured.'),
      newsletterSubscribeButton: t('SUBSCRIBE'),
      newsletterSuccess: t('Your e-mail subscription is now active!'),
      notificationsContent: t('Enable instant in-browser notification whenever new contest starts.'),
      submit: t('Submit'),
      title: t('DON’T miss next contest!'),
    }),
    [t],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const newsletter = useNewsletter();
  const subscribe = useSubscribe();
  const [
    isSuccessMessageDisplayed,
    setIsSuccessMessageDisplayed,
  ] = useState(false);
  const [isNewsletterVisible, setIsNewsletterVisible] = useState(false);

  useEffect(
    () => {
      if (newsletter.isFulfilled) {
        setIsSuccessMessageDisplayed(true);

        setTimeout(
          () => {
            setIsSuccessMessageDisplayed(false);
          },
          10000,
        );
      }
    },
    [newsletter, setIsSuccessMessageDisplayed],
  );

  const onSubmit: SubmitHandler<any> = data => {
    const { email } = data;

    if (email) {
      subscribe({
        email,
        subscriptions: subscriptionsActivated,
      });
    }
  };

  const handleNewsletterClick = useCallback(
    () => setIsNewsletterVisible(true),
    [setIsNewsletterVisible],
  );

  const displaySuccessMessage = useCallback(
    () => (
      <span className="Subscriptions-EmailMessage Subscriptions-EmailMessage--Success">
        <img
          alt="Check icon"
          className="Subscriptions-EmailMessageIcon"
          src={checkImg}
        />

        {text.newsletterSuccess}
      </span>
    ),
    [text],
  );

  const displayErrorMessages = useCallback(
    () => {
      const errorMessages = Array.isArray(newsletter?.error?.message)
        ? newsletter.error.message
          .map(errorMessage => {
            switch (errorMessage) {
              case 'email must be an email':
                return text.errorEmailPattern;

              case 'email should not be empty':
                return text.errorEmailEmpty;

              case 'lang must be a valid enum value':
                return text.errorLangValue;

              case 'each value in subscriptions must be a valid enum value':
                return text.errorSubscriptions;

              case 'subscriptions must be an array':
                return text.errorSubscriptionsArray;

              default:
                return text.errorDefault;
            }
          }).toString()
        : text.errorDefault;

      return (
        <span className="Subscriptions-EmailMessage Subscriptions-EmailMessage--Error">
          {errorMessages}
        </span>
      );
    },
    [newsletter, text],
  );

  return (
    <section className={rootClassName}>
      <div className="Subscriptions-BackgroundBox">
        <h2 className="Subscriptions-Title">
          {isEmbedded ? text.embeddedTitle : text.title}
        </h2>
        <div className="row justify-content-md-center">
          {!isIOS && (
            <div className="Subscription-Box col-12 col-md-7 col-lg-5">
              <div className="Subscription-Content">
                <img
                  alt="Bell icon"
                  className="Subscription-Icon"
                  src={bellIconImg}
                />
                <span className="Subscription-Text">{text.notificationsContent}</span>
              </div>
              <Button
                className="Subscription-Button"
                isFullWidth={false}
                onClick={handleNotificationsClick}
                size={Button.Size.DEFAULT}
                styleType={isSubscribedToNotifications ? Button.StyleType.LIGHT : Button.StyleType.PRIMARY}
              >
                {isSubscribedToNotifications ? text.disableNotificationsButton : text.enableNotificationsButton}
              </Button>
            </div>
          )}
          <div className="Subscription-Box col-12 col-md-7 col-lg-5">
            <div className="Subscription-Content">
              <img
                alt="Newsletter icon"
                className="Subscription-Icon"
                src={newsletterIconImg}
              />
              <span className="Subscription-Text">{text.newsletterContent}</span>
            </div>
            {!isNewsletterVisible && (
              <Button
                className="Subscription-Button"
                isFullWidth={false}
                onClick={handleNewsletterClick}
                size={Button.Size.DEFAULT}
              >
                {text.getNewsletterButton}
              </Button>
            )}
            {isNewsletterVisible && (
              <>
                <form
                  className="Subscription-Form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <TextInput
                    className="Subscription-TextInput"
                    defaultValue={''}
                    error={emailRule(errors[FormField.EMAIL], text)}
                    isRequired={true}
                    name={FormField.EMAIL}
                    placeholder={text.emailPlaceholder}
                    register={register}
                    type={TextInput.Type.EMAIL}
                    validationPattern={EMAIL_PATTERN}
                  />
                  <Button
                    className="Subscription-FormButton"
                    isDisabled={newsletter.isRequested || isSuccessMessageDisplayed}
                    onClick={handleNewsletterClick}
                    size={Button.Size.DEFAULT}
                  >
                    {text.newsletterSubscribeButton}
                  </Button>
                </form>
                {isSuccessMessageDisplayed && displaySuccessMessage()}
                {newsletter?.isRejected && displayErrorMessages()}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const Container: Component = (props: Props) => {
  const [isSubscribedToNotifications, setIsSubscribedToNotifications] = useState(false);

  const setNotificationsState = () => {
    const getBackCookie = getCookie('gb_is_push_subscriber');
    let isPermissionGranted = false;

    try {
      if (Notification.permission == 'granted') {
        isPermissionGranted = true;
      }
    } catch (e) {
      isPermissionGranted = false;
    }

    const isSubscribed = isPermissionGranted && getBackCookie == '1';
    setIsSubscribedToNotifications(isSubscribed);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNotificationsState();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleNotificationsClick = useCallback(
    () => {
      isSubscribedToNotifications
        ? window.gb_disable_push?.()
        : window.gb_enable_push?.();
    },
    [isSubscribedToNotifications],
  );

  if (!document || !window) {
    return null;
  }

  const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;

  const addedProps = {
    handleNotificationsClick,
    isIOS,
    isSubscribedToNotifications,
  };

  return (
    <Subscriptions {...props} {...addedProps} />
  );
};

const ComposedSubscriptions = isServer ? withTranslation(Subscriptions) : withTranslation(Container);

export {
  ComposedSubscriptions as Subscriptions,
  Subscriptions as SubscriptionsComponent,
};
