import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { updateStatus } from 'dde-app/contest/redux/flight/actions';

type UseUpdateStatusReturn = typeof updateStatus;

const useUpdateStatus = (): UseUpdateStatusReturn => {
  const dispatch = useAppDispatch();
  return bindActionCreators(updateStatus, dispatch);
};

export {
  useUpdateStatus,
};
export type {
  UseUpdateStatusReturn,
};
