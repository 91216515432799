import React, { useCallback, useState } from 'react';
import { useGallery } from 'src/client/photos/gallery/hooks/useGallery';
import { Slider } from './Slider';
import { Component, Props } from './types';
import './Gallery.scss';

const EMPTY_NAME_LABEL = 'DayDeal';

const Gallery: Component = ({
  isHomePage = false,
}: Props) => {
  const photos = useGallery();
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const onToggleSlider = useCallback(
    (isVisible = !isSliderVisible, index?: number) => () =>  {
      setSlideIndex(index);
      setIsSliderVisible(isVisible);
    },
    [isSliderVisible],
  );

  if (!photos.isFulfilled) {
    return null;
  }

  const { data } = photos;
  const photosDisplayed = isHomePage ? data.slice(0, 6) : data;
  const columnStyles = isHomePage
    ? 'col-6 col-md-4 col-lg-2'
    : 'col-6 col-md-4';

  return (
    <>
      <section className="Gallery">
        <div className="Gallery-PhotosGrid row">
          {photosDisplayed.map((photo, key) => (
            <div
              className={columnStyles}
              key={key}
            >
              <div
                className="Gallery-PhotoWrapper"
                onClick={onToggleSlider(true, key)}
              >
                <div className="Gallery-PhotoBox">
                  <img
                    alt={`photo ${key + 1}`}
                    className="Gallery-Photo"
                    src={photo.filePathThumbnail || photo.filePath}
                  />
                  <span className="Gallery-UserName">{photo.userName || EMPTY_NAME_LABEL}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {isSliderVisible && (
        <Slider
          isSubpageButtonDisplayed={isHomePage}
          onToggleSlider={onToggleSlider}
          galleryPhotos={data}
          slideIndex={slideIndex}
        />
      )}
    </>
  );
};

export {
  Gallery,
};
