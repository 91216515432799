import React, { ReactElement, useMemo } from 'react';
import SkywalkerLogoImg from 'dde-app/common/assets/icons/icon-skywalker.svg';
import Balloon1Img from 'dde-app/common/assets/images/event-info-balloon-1.jpeg';
import Balloon2Img from 'dde-app/common/assets/images/event-info-balloon-2.jpg';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import { useFlightsSummary } from 'dde-app/home/hooks/useFlightsSummary';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { DataPair } from './DataPair';
import { Component, Props } from './';
import './EventInfo.scss';

const EventInfo: Component = ({ t }: Props): ReactElement => {
  const flightsSummary = useFlightsSummary();
  const data = flightsSummary.isFulfilled ? flightsSummary.data : null;
  const text = useMemo(
    () => ({
      capacity: t('Capacity'),
      capacityValue: t('3/4 pax.', {}, 'Capacity value'),
      diameter: t('Diameter'),
      diameterValue: t('16,9 m', {}, 'Diameter value'),
      height: t('Height'),
      heightValue: t('17 M', {}, 'Height value'),
      hours: t('hours'),
      km: t('km'),
      skywalkerInfo: t('Flights are handled by'),
      skywalkerName: t('skywalker-balloon.ch'),
      subtitle1: t('Curious about our new toy?'),
      subtitle2: t('Here’s a few facts.'),
      title: t('The Balloon'),
      totalDistance: t('Total distance travelled'),
      totalFlightTime: t('Total flight time'),
      totalFlights: t('Total flights'),
      volume: t('Volume'),
      volumeValue: t('2200 m3', {}, 'Volume value'),
    }),
    [t],
  );

  return (
    <section className="EventInfo" id={ElementId.EVENT_INFO}>
      <div className="container">
        <h2 className="EventInfo-Title text-center">
          {text.title}
        </h2>

        <p className="EventInfo-Subtitle text-center">
          {text.subtitle1}<br />
          {text.subtitle2}
        </p>

        <div className="row EventInfo-TopData justify-content-md-center">
          {data?.totalFlights && (
            <DataPair
              className="col-12 col-md-3"
              data={`${data.totalFlights}`}
              label={text.totalFlights}
            />
          )}

          {data?.totalTimeInHours && (
            <DataPair
              className="col-12 col-md-3"
              data={`${data.totalTimeInHours} ${text.hours}`}
              label={text.totalFlightTime}
            />
          )}

          {data?.totalDistance && (
            <DataPair
              className="col-12 col-md-3"
              data={`${Math.floor(data.totalDistance)} ${text.km}`}
              label={text.totalDistance}
            />
          )}
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="d-flex justify-content-end align-self-center col-3 col-md-3">
                <DataPair
                  data={text.heightValue}
                  label={text.height}
                />
              </div>

              <div className="col-9  col-md-7">
                <img className="EventInfo-Image" src={Balloon1Img} alt="Balloon Image 1" />

                <DataPair
                  className="EventInfo-Capacity"
                  data={text.capacityValue}
                  label={text.capacity}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-7 col-md-9">
                <img className="EventInfo-Image" src={Balloon2Img} alt="Balloon Image 2" />
              </div>

              <div className="EventInfo-BottomData col-5 col-md-3">
                <DataPair
                  data={text.diameterValue}
                  label={text.diameter}
                />

                <DataPair
                  data={text.volumeValue}
                  label={text.volume}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="EventInfo-Skywalker">
          <img src={SkywalkerLogoImg} alt="Skywalker Logo" />

          <span className="EventInfo-SkywalkerInfo">
            {text.skywalkerInfo}
          </span>

          <a className="EventInfo-SkywalkerName"
             href="https://www.skywalker-ballon.ch/daydeal-ballon"
             target="_blank"
             rel="noreferrer"
          >
            {text.skywalkerName}
          </a>
        </div>
      </div>
    </section>
  );
};

const ComposedEventInfo = withTranslation(EventInfo);

export {
  ComposedEventInfo as EventInfo,
  EventInfo as EventInfoComponent,
};
