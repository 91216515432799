import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { useAppSelector } from 'dde-app/app/hooks/useAppSelector';
import { RemoteRecord } from 'dde-app/common/redux/RemoteRecord';
import { fetch } from 'dde-app/contest/redux/flight/actions';
import { getFlightData } from 'dde-app/contest/redux/flight/selectors';
import { FlightData } from 'dde-app/contest/redux/flight/types';

const useFlightData = (): RemoteRecord<FlightData | null> => {
  const dispatch = useAppDispatch();

  const flightData = useAppSelector(
    useCallback(
      state => getFlightData(state),
      [],
    ),
  );

  useEffect(
    () => {
      if (!flightData?.wasStarted) {
        dispatch(fetch());
      }
    },
    [dispatch, flightData?.wasStarted],
  );

  return flightData;
};

export {
  useFlightData,
};
