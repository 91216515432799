/* eslint-disable react/jsx-no-target-blank */

import React, { ReactElement, useMemo } from 'react';
import facebook from 'dde-app/common/assets/icons/icon-facebook.svg';
import instagram from 'dde-app/common/assets/icons/icon-instagram.svg';
import skywalker from 'dde-app/common/assets/icons/icon-skywalker-white.svg';
import twitter from 'dde-app/common/assets/icons/icon-twitter.svg';
import youtube from 'dde-app/common/assets/icons/icon-youtube.svg';
import { Link } from 'dde-app/common/components/Link';
import { Logo } from 'dde-app/common/components/Logo';
import { ElementId } from 'dde-app/home/components/HomePage/HomePage';
import { withTranslation } from 'dde-app/i18n/hoc/withTranslation';
import { Component, Props } from './';
import './Footer.scss';

const Footer: Component = ({ onScrollToClick, t }: Props): ReactElement | null => {
  const text = useMemo(
    () => ({
      about: t('About'),
      aboutDaydeal: t('Über daydeal'),
      aboutEvent: t('Über ballon'),
      eventContest: t('Ballon contest'),
      faq: t('FAQ'),
      geschenkkarten: t('Geschenkkarten'),
      impressum: t('Impressum'),
      neuigkeiten: t('Neuigkeiten'),
      partner: t('Partner'),
      partnerName: t('skywalker-ballon.ch'),
      photoContest: t('Photo contest'),
      unternehmen: t('Unternehmen'),
    }),
    [t],
  );

  return (
    <footer className="Footer">
      <div className="container">
        <div className="row">
          <div className="Footer-Logo col-12 col-md-3 col-lg-2">
            <Logo/>
          </div>
          <div className="Footer-LinksWidget col-12 col-md-3 col-lg-2">
            <h3 className="Footer-WidgetTitle">{text.aboutDaydeal}</h3>
            <ul className="Footer-WidgetItems">
              <li className="Footer-WidgetItem"><a className="Footer-Link" href={'https://www.daydeal.ch/neuigkeiten'} target="_blank" >{text.neuigkeiten}</a></li>
              <li className="Footer-WidgetItem"><a className="Footer-Link" href={'https://www.daydeal.ch/seite/unternehmen'} target="_blank" >{text.unternehmen}</a></li>
              <li className="Footer-WidgetItem"><a className="Footer-Link" href={'https://www.daydeal.ch/seite/impressum'} target="_blank" >{text.impressum}</a></li>
              <li className="Footer-WidgetItem"><a className="Footer-Link" href={'https://www.daydeal.ch/seite/geschenkkarten'} target="_blank" >{text.geschenkkarten}</a></li>
            </ul>
          </div>
          <div className="Footer-LinksWidget col-12 col-md-3 col-lg-2">
            <h3 className="Footer-WidgetTitle">{text.aboutEvent}</h3>
            <ul className="Footer-WidgetItems">
              <li className="Footer-WidgetItem">
                <a
                  className="Footer-Link"
                  href="/"
                  onClick={onScrollToClick(ElementId.MAP)}
                >
                  {text.eventContest}
                </a>
              </li>
              <li className="Footer-WidgetItem">
                <a
                  className="Footer-Link"
                  href="/"
                  onClick={onScrollToClick(ElementId.PHOTO_CONTEST)}
                >
                  {text.photoContest}
                </a>
              </li>
              <li className="Footer-WidgetItem">
                <a
                  className="Footer-Link"
                  href="/"
                  onClick={onScrollToClick(ElementId.EVENT_INFO)}
                >
                  {text.about}
                </a>
              </li>
              <li className="Footer-WidgetItem">
                <Link className="Footer-Link" url={'/page/faq'} >
                  {text.faq}
                </Link>
              </li>
            </ul>
          </div>
          <div className="Footer-Partner col-12 col-md-3 col-lg-2">
            <h3 className="Footer-WidgetTitle">{text.partner}</h3>
            <a className="Footer-PartnerLink" href={'https://www.skywalker-ballon.ch/'} target="_blank">
              <img className="Footer-PartnerIcon" src={skywalker} alt="skywalker icon" />
              <div className="Footer-PartnerName">{text.partnerName}</div>
            </a>
          </div>
          <ul className="Footer-SocialIcons col-12 col-md-3 col-lg-2 offset-lg-2">
            <li className="Footer-SocialIcon"><a href={'https://twitter.com/daydeal'} target="_blank" ><img src={twitter} alt="twitter" /></a></li>
            <li className="Footer-SocialIcon"><a href={'https://www.facebook.com/DayDeal.ch'} target="_blank" ><img src={facebook} alt="facebook" /></a></li>
            <li className="Footer-SocialIcon"><a href={'https://www.instagram.com/daydeal.ch/'} target="_blank" ><img src={instagram} alt="instagram" /></a></li>
            <li className="Footer-SocialIcon"><a href={'https://www.youtube.com/user/DayDealTV '} target="_blank" ><img src={youtube} alt="youtube" /></a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

const ComposedFooter = withTranslation(Footer);

export {
  ComposedFooter as Footer,
  Footer as FooterComponent,
};
