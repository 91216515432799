import React, { ReactElement } from 'react';
import { HashLink as ReactRouterLink } from 'react-router-hash-link';
import { Component, Props } from './types';

const Link: Component = ({
  className,
  children,
  url,
  rest,
}: Props): ReactElement => (
  <ReactRouterLink className={className} to={url} {...rest}>
    {children}
  </ReactRouterLink>
);

export {
  Link,
};
