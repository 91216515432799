import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { clearSubmitPhotoContest } from 'dde-app/photos/photoContest/redux/actions';

type UseClearSubmitPhotoContestReturn = typeof clearSubmitPhotoContest;

const useClearSubmitPhotoContest = (): UseClearSubmitPhotoContestReturn => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => bindActionCreators(clearSubmitPhotoContest, dispatch),
    [dispatch],
  );
};

export {
  useClearSubmitPhotoContest,
};
export type {
  UseClearSubmitPhotoContestReturn,
};
