import { StatsD } from 'hot-shots';
import { IMetricsClient } from 'src/server/metrics/types';
import { logger } from 'src/server/utils/winston';
import { getConfig } from 'dde-app/config/utils/config';

const APP_NAME = getConfig('appName');

function MetricsClient(): IMetricsClient {
  const isEnabled = getConfig('metrics.enabled');
  let instance: typeof StatsD | typeof undefined;

  function getInstance(): StatsD {
    if (!instance) {
      instance = new StatsD({
        errorHandler: (error) => {
          logger.error('Socket errors caught here: ', error.message);
        },
        globalTags: {
          app_env: getConfig('env'),
          app_name: APP_NAME,
        },

        host: process.env.DD_AGENT_HOST,

        port: parseInt(process.env.DD_AGENT_PORT, 10),

        prefix: `${APP_NAME}.`,
        // @ts-ignore
        protocol: process.env.DD_AGENT_PROTOCOL,
        sampleRate: parseInt(process.env.DD_SAMPLE_RATE, 10),
      });
    }

    return instance;
  }

  function increment(name: string): void {
    if (!isEnabled) {
      return;
    }

    getInstance().increment(name);
  }

  function reportErrorEvent(message: string): void {
    if (!isEnabled) {
      return;
    }

    getInstance().event(
      'error',
      message,
      {
        alert_type: 'error',
      },
    );
  }

  function timing(name: string, value: number): void {
    if (!isEnabled) {
      return;
    }

    getInstance().timing(name, value);
  }

  return {
    increment,
    reportErrorEvent,
    timing,
  };
}

const metrics = MetricsClient();

export {
  metrics,
};
