import { all, call, put, takeEvery } from 'redux-saga/effects';
import { requestApiSaga } from 'dde-app/common/redux/api/requestApiSaga';
import { reportError } from 'dde-app/common/redux/error/actions';
import { parseError } from 'dde-app/common/utils/api/error';
import { GalleryConnector } from 'dde-app/photos/gallery/api/GalleryConnector';
import {
  fetch,
  fetchFulfilled,
  fetchRejected,
} from './actions';
import { FetchAction } from './types';

function* fetchSaga(action: FetchAction): Generator<any, any, any> {
  const defaultSkip = 0;
  const defaultLimit = 100;
  const { skip, limit } = action.payload;

  try {
    const response = yield call(
      requestApiSaga,
      GalleryConnector.fetch(
        skip || defaultSkip,
        limit || defaultLimit,
      ),
    );

    yield put(
      fetchFulfilled({
        gallery: GalleryConnector.mapResponseToState(response),
      }),
    );
  } catch (error) {
    yield put(
      fetchRejected({
        error: parseError(error),
      }),
    );
    yield put(
      reportError({
        error,
        message: action.type,
      }),
    );
  }
}

function* saga(): Generator<any, any, any> {
  yield all([
    takeEvery(fetch.type, fetchSaga),
  ]);
}

export {
  saga,
};
