import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'dde-app/app/hooks/useAppDispatch';
import { submitPhotoContest } from 'dde-app/photos/photoContest/redux/actions';

type UseSubmitPhotoContest = typeof submitPhotoContest;

const useSubmitPhotoContest = (): UseSubmitPhotoContest => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => bindActionCreators(submitPhotoContest, dispatch),
    [dispatch],
  );
};

export {
  useSubmitPhotoContest,
};
export type {
  UseSubmitPhotoContest,
};
