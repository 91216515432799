import { createSlice } from '@reduxjs/toolkit';
import { RemoteRecordStateProvider } from 'dde-app/common/redux/RemoteRecordStateProvider';
import { FetchFulfilledAction, FetchRejectedAction, Reward, State } from './types';

const SLICE_NAME = 'reward';

const initialState: State = null;

const slice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    fetch: () => {
      return RemoteRecordStateProvider.getRequested<Reward>();
    },

    fetchFulfilled: (state, action: FetchFulfilledAction) => {
      return RemoteRecordStateProvider.getFulfilled<Reward>(action.payload);
    },

    fetchRejected: (state, action: FetchRejectedAction) => {
      return RemoteRecordStateProvider.getRejected<Reward>(action.payload);
    },
  },
});

export {
  slice,
  SLICE_NAME,
};
