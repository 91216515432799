import { FlightStatus, getElapsedTimeInMinutes } from 'dde-app/contest/utils/flight';

enum ContestStatus {
  PREPARING_FOR_TAKE_OFF = 'preparing_for_take_off',
  ACTIVE = 'active',
  ACTIVE_NO_VOTING = 'active_no_voting',
  ANNOUNCEMENT = 'announcement',
  ERROR = 'error',
  INACTIVE = 'inactive',
  PREANNOUNCEMENT = 'preannouncement',
}

enum StorageKey {
  HAS_ALREADY_VOTED = 'has-already-voted',
  SUBMITTED_DATA = 'submitted-data',
  SUBMITTED_POSITION = 'submitted-position',
}

// TODO: wrap in function expecting translate function as argument
const contestStatusLabel: { [key in ContestStatus]?: string } = {
  [ContestStatus.PREPARING_FOR_TAKE_OFF]: 'Preparing for take off',
  [ContestStatus.ACTIVE]: 'Active',
  [ContestStatus.ACTIVE_NO_VOTING]: 'Active - no voting',
  [ContestStatus.ANNOUNCEMENT]: 'Announcement',
  [ContestStatus.ERROR]: 'Error / problem',
  [ContestStatus.INACTIVE]: 'Inactive',
  [ContestStatus.PREANNOUNCEMENT]: 'Preannouncement',
};

const isContestLive = (status: ContestStatus): boolean => [
  ContestStatus.PREPARING_FOR_TAKE_OFF,
  ContestStatus.ACTIVE,
  ContestStatus.ACTIVE_NO_VOTING,
  ContestStatus.PREANNOUNCEMENT,
].includes(status);

const getStorageKeyForContest = (key: StorageKey, flightUuid: string): string => `${key}#${flightUuid}`;

const getContestStatus = (flightStatus: FlightStatus, landingTime?: string | null): ContestStatus => {
  switch (flightStatus) {
    case FlightStatus.ISSUE:
      return ContestStatus.ERROR;
    case FlightStatus.TAKING_OFF:
      return ContestStatus.PREPARING_FOR_TAKE_OFF;
    case FlightStatus.IN_FLIGHT:
      return ContestStatus.ACTIVE;
    case FlightStatus.LANDING:
      return ContestStatus.ACTIVE_NO_VOTING;
    case FlightStatus.LANDED:
      return ContestStatus.PREANNOUNCEMENT;
    case FlightStatus.FINISHED: {
      const elapsedTime = getElapsedTimeInMinutes(new Date(landingTime).getTime());
      if (elapsedTime < 60) {
        return ContestStatus.ANNOUNCEMENT;
      }
      return ContestStatus.INACTIVE;
    }
    default:
      return ContestStatus.INACTIVE;
  }
};

export {
  ContestStatus,
  contestStatusLabel,
  getContestStatus,
  getStorageKeyForContest,
  isContestLive,
  StorageKey,
};
