import { ANALYTICS_BOOLEAN, ANALYTICS_INTERACTION_ID } from 'dde-app/analytics/utils/analytics';
import { RootState } from 'dde-app/app/redux/types';
import { getConfig } from 'dde-app/config/utils/config';
import { getLanguage } from 'dde-app/i18n/redux/selectors';

const getAnalyticsCommonData = (state: RootState): any => {
  const url = window?.location?.href || null;
  const title = document?.title || null;

  return {
    cart: {
      value: null,
    },
    client: {
      id: null,
      sid: null,
    },
    interaction: ANALYTICS_INTERACTION_ID.generic,
    page: {
      id: null,
      language: getLanguage(state),
      name: null,
      type: null,
    },
    page_title: title,
    platform: getConfig('analytics.platform'),
    request: {
      // @ts-ignore
      id: window?.requestId || null,
    },
    server: {
      code: 200,
    },
    tech_applicationVersion: getConfig('version'),
    url_full: url,
    user: {
      accid: null,
      debid: null,
      email: null,
    },
    virtualPageView: ANALYTICS_BOOLEAN.true,
  };
};

export {
  getAnalyticsCommonData,
};
